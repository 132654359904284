import React from "react";

const CEmpty = ({ image, title, description, ...props }) => {
  return (
    <div {...props}>
      {image && image}
      {title && <span className="font-label fw-semibold">{title}</span>}
      {description && (
        <span className="font-description text-secondary">{description}</span>
      )}
    </div>
  );
};

export default CEmpty;
