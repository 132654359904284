import { gql } from "@apollo/client";

const QueryParameters = gql`
  query Parameters($filter: String) {
    parameters(filter: $filter) {
      section
      sectionDisplayName
      value
      unit
      valueDisplayName
      origin {
        key
        value
      }
    }
  }
`;

export default QueryParameters;
