import { useLazyQuery, useQuery } from "@apollo/client";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CLoading from "../../components/CLoading";
import CModalSide from "../../components/CModalSide";
import MetaHelmet from "../../components/MetaHelmet";
import { GlobalContext } from "../../contexts/GlobalContext";
import QuarterResult from "../../graphQL/Query/QuarterResult";
import QueryDetail from "../../graphQL/Query/QueryDetail";
import QuerySummary from "../../graphQL/Query/QuerySummary";
import { isCurrencyNumber } from "../../utils/isCurrency";
import isRandomColor from "../../utils/IsRandomColor";
import fieldAnnualIncome from "./fieldTable/fieldAnnualIncome.json";
import fieldBalanceSheets from "./fieldTable/fieldBalanceSheet.json";
import fieldCashFlows from "./fieldTable/fieldCashFlow.json";
import fieldCategoryChart from "./fieldTable/fieldCategoryChart.json";
import fieldPeer from "./fieldTable/fieldPeerComparison.json";
import fieldQuarterly from "./fieldTable/fieldQuarterly.json";
import fieldRatios from "./fieldTable/fieldRatio.json";
import SectionAnalys from "./sectionComponent/SectionAnalys";
import SectionBalance from "./sectionComponent/SectionBalance";
import SectionCashFlow from "./sectionComponent/SectionCashFlow";
import SectionChart from "./sectionComponent/SectionChart";
import SectionPeer from "./sectionComponent/SectionPeer";
import SectionProfitLoss from "./sectionComponent/SectionProfitLoss";
import SectionQuarter from "./sectionComponent/SectionQuarter";
import SectionRatio from "./sectionComponent/SectionRatio";
import SectionShareholding from "./sectionComponent/SectionShareholding";
import SectionSummary from "./sectionComponent/SectionSummary";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  BarController,
  TimeScale,
  ChartDataLabels,
  zoomPlugin,
  Filler,
  ArcElement
);

const Company = () => {
  const { companyId } = useParams();
  const { state, updateCompanyName } = useContext(GlobalContext);

  const [companiesDetail, setCompaniesDetail] = useState([]);
  const [ratio, setRatio] = useState({});
  const { t } = useTranslation("global");
  const [isExpandedAbout, setIsExpandedAbout] = useState(false);
  const [isOpenMore, setIsOpenMore] = useState(false);
  const [isOpenCompre, setIsOpenCompre] = useState(false);
  const [isCompareActive, setIsCompareActive] = useState(false);
  const [isTradingView, setIsTradingView] = useState(false);

  //for compound
  const [compoundSalesGrowth, setCompoundSalesGrowth] = useState(null);
  const [dataSetSalesGrowth, setDataSetSalesGrowth] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsSalesGrowth, setOptionsSalesGrowth] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });
  const [compoundProfitGrowth, setCompoundProfitGrowth] = useState(null);
  const [dataSetProfitGrowth, setDataSetProfitGrowth] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsProfitGrowth, setOptionsProfitGrowth] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });

  const [compoundstockPriceCagr, setCompoundStockPriceCagr] = useState(null);
  const [dataSetStockPriceCagr, setDataSetStockPriceCagr] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsStockPriceCagr, setOptionsStockPriceCagr] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });

  const [returnOnEquity, setReturnOnEquity] = useState(null);
  const [dataSetReturnOnEquity, setDataSetReturnOnEquity] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsReturnOnEquity, setOptionsReturnOnEquity] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });

  // for Peer Comparison
  const [tooltipOpenPeer, setTooltipOpenPeer] = useState({});
  const [fieldPeerComparison] = useState(fieldPeer);
  const [peerComparison, setPeerComparison] = useState([]);

  // for Quarter Result
  const [quarter, setQuarter] = useState([]);
  const [fieldQuarter, setFieldQuarter] = useState([]);
  const [objectQuarter, setObjectQuarter] = useState(fieldQuarterly);

  // for Profit Loss
  const [profitLoss, setProfitLoss] = useState([]);
  const [fieldProfitLoss, setFieldProfitLoss] = useState([]);
  const [objectProfitLoss, setObjectProfitLoss] = useState(fieldAnnualIncome);

  // for Balance Sheet
  const [balanceSheet, setBalanceSheet] = useState([]);
  const [fieldBalanceSheet, setFieldBalanceSheet] = useState([]);
  const [objectBalanceSheet, setObjectBalanceSheet] =
    useState(fieldBalanceSheets);

  // for chart
  const [coreChartCompanies, setCoreChartCompanies] = useState([]);
  const [companyIds, setCompanyIds] = useState([companyId]);
  const [dataSet, setDataSet] = useState({
    labels: [],
    datasets: [],
  });
  const [dataOptions, setDataOptions] = useState({
    responsive: true,
    interaction: {},
    plugins: {},
    scales: {},
  });
  const [dataSetTradingView, setDataSetTradingView] = useState({
    data: [],
  });

  const [optionsTradingView] = useState({
    chart: {
      type: "candlestick",
      height: 400,
    },

    xaxis: {
      type: "datetime",
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  });

  const [filterCategoryChart] = useState(fieldCategoryChart);
  const [filterCategoryChartSelected, setFilterCategoryChartSelected] =
    useState(fieldCategoryChart[0]);
  const [filterDateChartSelected, setFilterDateChartSelected] = useState({
    label: "1Yr",
    dateFrom: moment().subtract(1, "year").format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
  });
  const [filterDateChart] = useState([
    {
      label: "1m",
      ratio: 4,
      dateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "6m",
      ratio: 24,
      dateFrom: moment().subtract(6, "month").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "1Yr",
      ratio: 48,
      dateFrom: moment().subtract(1, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "3Yr",
      ratio: 100,
      dateFrom: moment().subtract(3, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "5Yr",
      ratio: 1000,
      dateFrom: moment().subtract(5, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },

    {
      label: "10Yr",
      ratio: 5000,
      dateFrom: moment().subtract(10, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "Max",
      ratio: 120,
      dateFrom: moment().subtract(50, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
  ]);

  // for cash flow
  const [cashFlow, setCashFlow] = useState([]);
  const [fieldCashFlow, setFieldCashFlow] = useState([]);
  const [objectCashFlow, setObjectCashFlow] = useState(fieldCashFlows);

  // for ratio
  const [ratioData, setRatioData] = useState([]);
  const [fieldRatio, setFieldRatio] = useState([]);
  const [objectRatio] = useState(fieldRatios);

  // for shareholding pattern
  const [shareHoldingPattern, setShareHoldingPattern] = useState([]);
  const [dataSetShareHoldingPattern, setDataSetShareHoldingPattern] = useState({
    labels: [],
    datasets: [],
  });

  const [dataOptionsShareHoldingPattern, setDataOptionsShareHoldingPattern] =
    useState({
      responsive: true,
      interaction: {},
      plugins: {},
      scales: {},
    });

  // for parameters
  const { loading } = useQuery(QuerySummary, {
    variables: {
      code: companyId || "",
    },
    onCompleted: (data_) => {
      setCompaniesDetail(data_?.company[0]);
    },

    onError: (error) => {
      console.log(error);
    },
  });

  // FETCH COMPANY DETAIL
  const [fetchCompanyDetail] = useLazyQuery(QueryDetail, {
    variables: {
      code: companyId || "",
    },
    onCompleted: (data_) => {
      setRatio(
        data_?.company[0].ratio.length > 0 ? data_?.company[0].ratio[0] : {}
      );
      setRatioData(data_?.company[0].ratio);
      onModifyCompound(data_?.company[0]);

      onModifyRatio(data_?.company[0].ratio);
    },

    onError: (error) => {
      console.log(error);
    },
  });

  // FETCH CHART
  const [fetchChart] = useLazyQuery(QueryDetail);

  // FETCH QUARTER
  const [fetchQuarter] = useLazyQuery(QuarterResult, {
    variables: {
      code: companyId || "",
    },
    onCompleted: (data_) => {
      setPeerComparison(data_?.company[0]?.peerComparison);
      onModifyQuarter(data_?.company[0]?.quarterlyIncome);
      onModifyBalanceSheet(data_?.company[0]?.balanceSheet);
      onModifyCashFlow(data_?.company[0]?.cashFlow);
      onModifyAnnualIncome(data_?.company[0]?.annualIncome);
      onModifyShareHoldingPattern(data_?.company[0].stockHolders);
    },

    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    fetchCompanyDetail();
    fetchQuarter();
    onFetchChart(companyId);
    // eslint-disable-next-line
  }, []);

  // FETCH CHART
  const onFetchChart = async (companiesId, isCompare) => {
    const result = await fetchChart({
      variables: {
        code: companiesId || "",
      },
    });

    if (!result?.result?.loading) {
      updateCompanyName(result?.data?.company[0]);
      let newCoreChartCompanies = [...coreChartCompanies];
      if (
        coreChartCompanies?.filter(
          (item) =>
            item?.code === result?.data?.company[0]?.code &&
            item?.code !== companyId
        ).length > 0
      ) {
        newCoreChartCompanies.splice(
          newCoreChartCompanies.indexOf(
            coreChartCompanies?.filter(
              (item) => item?.code === result?.data?.company[0]?.code
            )[0]
          ),
          1
        );
      } else {
        newCoreChartCompanies.push(result?.data?.company[0]);
      }
      setCoreChartCompanies(newCoreChartCompanies);

      if (isCompare) {
        onModifyCompareChart(
          newCoreChartCompanies,
          filterCategoryChartSelected,
          filterDateChartSelected
        );
      } else {
        onModifyChart(
          result?.data?.company[0],
          filterCategoryChartSelected,
          filterDateChartSelected
        );
      }
    }
  };

  const onSelectCategoryChart = async (data) => {
    await setIsCompareActive(false);
    await setCompanyIds([companyId]);
    await setCoreChartCompanies(coreChartCompanies);
    await setFilterCategoryChartSelected(data);
    await onModifyChart(coreChartCompanies[0], data, filterDateChartSelected);
  };

  const onSelectDateChart = async (data) => {
    await setFilterDateChartSelected(data);
    if (isCompareActive) {
      await onModifyCompareChart(
        coreChartCompanies,
        filterCategoryChartSelected,
        data
      );
    } else {
      await onModifyChart(
        coreChartCompanies[0],
        filterCategoryChartSelected,
        data
      );
    }
  };

  const onSwitchChange = async () => {
    await setIsCompareActive(false);
    await setCompanyIds([companyId]);
    await setCoreChartCompanies(coreChartCompanies);
    await setFilterCategoryChartSelected(fieldCategoryChart[0]);
    await onModifyChart(
      coreChartCompanies[0],
      filterCategoryChartSelected,
      filterDateChartSelected
    );
    await setIsTradingView(!isTradingView);
  };

  const onCompareCheck = async (item) => {
    let isCompare = false;
    let newCompaniesId = [...companyIds];
    if (newCompaniesId.includes(item)) {
      newCompaniesId.splice(newCompaniesId.indexOf(item), 1);
    } else {
      newCompaniesId.push(item);
    }
    if (newCompaniesId?.length > 1) {
      isCompare = true;
    } else {
      isCompare = false;
    }
    await setIsCompareActive(isCompare);
    await setCompanyIds(newCompaniesId);
    await onFetchChart(item, isCompare);
  };

  const onModifyCompareChart = (
    data,
    filterCategorySelected,
    filterDateSelected
  ) => {
    const { dateFrom, dateTo } = filterDateSelected;

    let filteredData = null;

    switch (filterCategorySelected?.slug) {
      case "closingPrice":
        filteredData = data?.map((item) => {
          return {
            name: item?.name,
            data: {
              date: item?.stockHistories
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dateCreated),
              value: item?.stockHistories
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.closingPrice),
            },
          };
        });

        break;
      case "stockPe":
        filteredData = data?.map((item) => {
          return {
            name: item?.name,
            data: {
              date: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dateCreated),
              value: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.stockPe),
            },
          };
        });

        break;
      case "eps":
        filteredData = data?.map((item) => {
          return {
            name: item?.name,
            data: {
              date: item?.annualIncome
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dateCreated),
              value: item?.annualIncome
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.eps),
            },
          };
        });

        break;
      case "dividendYield":
        filteredData = data?.map((item) => {
          return {
            name: item?.name,
            data: {
              date: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dateCreated),
              value: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dividendYield),
            },
          };
        });
        break;
      case "marketCapital":
        filteredData = data?.map((item) => {
          return {
            name: item?.name,
            data: {
              date: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dateCreated),
              value: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.marketCapital),
            },
          };
        });
        break;
      default:
        break;
    }

    const labels = filteredData[0]?.data?.date;
    const colors = [
      "#FF5733",
      "#33FF57",
      "#3357FF",
      "#FF33A1",
      "#F1C40F",
      "#9B59B6",
      "#1ABC9C",
      "#E74C3C",
      "#3498DB",
      "#2ECC71",
    ];

    setDataSet({
      labels: labels,
      datasets: filteredData?.map((item, index) => {
        return {
          type: "line",
          label: item?.name,
          data: item?.data?.value,
          borderColor: colors[index],
          backgroundColor: colors[index],
          borderWidth: 2,
          pointRadius: 0,
        };
      }),
    });

    setDataOptions({
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        datalabels: {
          display: false,
          align: "top",
          color: "#4a90e2",
          font: {
            size: 14,
            weight: "bold",
          },
          formatter: (value) => `${value}%`, // Format value as percentage
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          intersect: false,
          axis: "x",
          backgroundColor: "white",
          titleColor: "black",
          bodyColor: "black",
          padding: 10,
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          titleFont: {
            size: 14,
            weight: "bold",
          },
          bodyFont: {
            size: 12,
          },
          callbacks: {
            title: function (context) {
              return moment(context[0]?.label).format("D MMM YYYY");
            },
            label: function (context) {
              let label = ` ${context.dataset.label}: ${isCurrencyNumber(
                context.raw
              )} `;
              return ` ${label}`;
            },
          },
        },
      },

      hover: {
        mode: "index",
        intersect: false,
      },

      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            callback: function (value, index, values) {
              const totalDates = values.length;
              if (totalDates >= 100) {
                return index % 6 === 0
                  ? moment(labels[index]).format("MMM YY")
                  : "";
              } else {
                return index % 6 === 0
                  ? moment(labels[index]).format("D MMM")
                  : "";
              }
            },
          },
          grid: {
            display: false,
          },
        },
      },
    });
  };

  const onModifyChart = (data, filterCategorySelected, filterDateSelected) => {
    const { dateFrom, dateTo } = filterDateSelected;
    var filteredData = null;
    switch (filterCategorySelected?.slug) {
      case "closingPrice":
        filteredData = data?.stockHistories?.filter((item) =>
          moment(item.dateCreated).isBetween(dateFrom, dateTo, null, "[]")
        );
        onModifyChartPrice(
          filteredData,
          filterCategorySelected,
          filterDateSelected
        );
        onModifyChartTrading(
          filteredData,
          filterCategorySelected,
          filterDateSelected
        );
        break;
      case "stockPe":
        filteredData = data?.ratioCharts?.filter((item) =>
          moment(item.dateCreated).isBetween(dateFrom, dateTo, null, "[]")
        );
        onModifyChartPE(filteredData, filterCategorySelected);
        break;
      case "eps":
        filteredData = data?.annualIncome?.filter((item) =>
          moment(item.dateCreated).isBetween(dateFrom, dateTo, null, "[]")
        );
        onModifyChartEPS(filteredData, filterCategorySelected);
        break;
      case "dividendYield":
        filteredData = data?.ratioCharts?.filter((item) =>
          moment(item.dateCreated).isBetween(dateFrom, dateTo, null, "[]")
        );
        onModifyCharDividend(filteredData, filterCategorySelected);
        break;
      case "marketCapital":
        filteredData = data?.ratioCharts?.filter((item) =>
          moment(item.dateCreated).isBetween(dateFrom, dateTo, null, "[]")
        );
        onModifyMarketCap(filteredData, filterCategorySelected);
        break;
      default:
        break;
    }
  };

  const onModifyChartTrading = (data, filterCategorySelected) => {
    const newData = data?.map((item) => ({
      x: new Date(item.dateCreated), // Mengonversi string tanggal ke objek Date
      y: [
        item.openingPrice,
        item.highestPrice,
        item.lowestPrice,
        item.closingPrice,
      ],
    }));

    setDataSetTradingView([{ data: newData ? newData : [] }]);
  };
  const onModifyChartPrice = (data, filterCategorySelected) => {
    const newData = data?.map((item) => {
      return {
        date: item?.dateCreated,
        price: item?.closingPrice,
        volume: item?.tradeVolume,
        ema50: item?.ema50,
        ema100: item?.ema100,
        ema200: item?.ema200,
        sma50: item?.sma50,
        sma100: item?.sma100,
        sma200: item?.ema200,
      };
    });
    const labels = newData?.map((item) =>
      moment(item.date).format("YYYY-MM-DD")
    );
    setDataSet({
      labels: labels,
      datasets: filterCategorySelected?.children.map((item) => {
        return {
          type: item?.chart_type,
          label: item?.label,
          data: newData?.map((item_) => item_?.[item?.slug]),
          slug: item?.slug,
          borderColor: item?.color,
          backgroundColor: item?.color,
          borderWidth: 2,
          pointRadius: 0,
          hidden: item?.hidden,
          yAxisID: `y-axis-${item?.yAxis}`,
        };
      }),
    });
    setDataOptions({
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index", // Hover pada kedua chart sekaligus
        intersect: false,
      },
      plugins: {
        datalabels: {
          display: false, // Show data labels
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
          onClick: (e, legendItem) => {
            const index = legendItem.datasetIndex;
            const chart = e.chart;
            chart.getDatasetMeta(index).hidden =
              !chart.getDatasetMeta(index).hidden;
            chart.update();
          },
        },
        tooltip: {
          intersect: false,
          axis: "x",
          backgroundColor: "white",
          titleColor: "black",
          bodyColor: "black",
          padding: 10,
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          titleFont: {
            size: 14,
            weight: "bold",
          },
          bodyFont: {
            size: 12,
          },
          callbacks: {
            title: function (context) {
              return moment(context[0]?.label).format("D MMM YYYY");
            },
            label: function (context) {
              let label = `${context.dataset.label}: ${isCurrencyNumber(
                context.raw
              )} `;
              return `${label}`;
            },
          },
        },
      },

      hover: {
        mode: "index",
        intersect: false,
      },

      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,

            callback: function (value, index, values) {
              const totalDates = values.length;
              if (totalDates >= 100) {
                return index % 6 === 0
                  ? moment(labels[index]).format("MMM YY")
                  : "";
              } else {
                return index % 6 === 0
                  ? moment(labels[index]).format("D MMM")
                  : "";
              }
            },
          },
          grid: {
            display: false,
          },
        },
        "y-axis-price": {
          type: "linear",
          position: "right",
          title: {
            display: true,
            text: "Price on IDX",
          },
        },
        "y-axis-volume": {
          type: "linear",
          position: "left",
          title: {
            display: true,
            text: "Volume",
          },
          grid: {
            drawOnChartArea: false, // Nonaktifkan grid untuk sumbu volume
          },
        },
      },
    });
  };
  const onModifyChartPE = (data, filterCategorySelected) => {
    const newData = data?.map((item) => {
      return {
        date: item?.dateCreated,
        stockPe: item?.stockPe,
      };
    });
    const labels = newData?.map((item) =>
      moment(item.date).format("YYYY-MM-DD")
    );
    setDataSet({
      labels: labels,
      datasets: filterCategorySelected?.children?.map((item) => {
        return {
          type: item?.chart_type,
          label: item?.label,
          data: newData?.map((item_) => item_?.[item?.slug]),
          borderColor: item?.color,
          backgroundColor: item?.color,
          borderWidth: 2,
          pointRadius: 0,
        };
      }),
    });

    setDataOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          intersect: false,
          axis: "x",
          backgroundColor: "white",
          titleColor: "black",
          bodyColor: "black",
          padding: 10,
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          titleFont: {
            size: 14,
            weight: "bold",
          },
          bodyFont: {
            size: 12,
          },
          callbacks: {
            title: function (context) {
              return moment(context[0]?.label).format("D MMM YYYY");
            },
            label: function (context) {
              let label = `${context.dataset.label}: ${isCurrencyNumber(
                context.raw
              )} `;
              return `${label}`;
            },
          },
        },
      },
      hover: {
        mode: "index",
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,

            callback: function (value, index, values) {
              const totalDates = values.length;
              if (totalDates >= 100) {
                return index % 8 === 0
                  ? moment(labels[index]).format("MMM YY")
                  : "";
              } else {
                return index % 6 === 0
                  ? moment(labels[index]).format("D MMM")
                  : "";
              }
            },
          },
          grid: {
            display: false,
          },
        },
      },
    });
  };
  const onModifyChartEPS = (data, filterCategoryChartSelected) => {
    const newData = data?.map((item) => {
      return {
        date: item?.dateCreated,
        eps: item?.eps,
      };
    });
    const labels = newData?.map((item) =>
      moment(item.date).format("YYYY-MM-DD")
    );
    setDataSet({
      labels: labels,
      datasets: filterCategoryChartSelected?.children?.map((item) => {
        return {
          type: item?.chart_type,
          label: item?.label,
          data: newData?.map((item_) => item_?.[item?.slug]),
          borderColor: item?.color,
          backgroundColor: item?.color,
          borderWidth: 2,
          pointRadius: 0,
        };
      }),
    });
    setDataOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          intersect: false,
          axis: "x",
          backgroundColor: "white",
          titleColor: "black",
          bodyColor: "black",
          padding: 10,
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          titleFont: {
            size: 14,
            weight: "bold",
          },
          bodyFont: {
            size: 12,
          },
          callbacks: {
            title: function (context) {
              return moment(context[0]?.label).format("D MMM YYYY");
            },
            label: function (context) {
              let label = `${context.dataset.label}: ${isCurrencyNumber(
                context.raw
              )} `;
              return `${label}`;
            },
          },
        },
      },
      hover: {
        mode: "index",
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,

            callback: function (value, index, values) {
              const totalDates = values.length;
              if (totalDates >= 100) {
                return index % 6 === 0
                  ? moment(labels[index]).format("MMM YY")
                  : "";
              } else {
                return index % 6 === 0
                  ? moment(labels[index]).format("D MMM")
                  : "";
              }
            },
          },
          grid: {
            display: false,
          },
        },
      },
    });
  };
  const onModifyCharDividend = (data, filterCategoryChartSelected) => {
    const newData = data?.map((item) => {
      return {
        date: item?.dateCreated,
        dividendYield: item?.dividendYield,
      };
    });
    const labels = newData?.map((item) =>
      moment(item.date).format("YYYY-MM-DD")
    );

    setDataSet({
      labels: labels,
      datasets: filterCategoryChartSelected?.children?.map((item) => {
        return {
          type: item?.chart_type,
          label: item?.label,
          data: newData?.map((item_) => item_?.[item?.slug]),
          borderColor: item?.color,
          backgroundColor: item?.color,
          borderWidth: 2,
          pointRadius: 0,
        };
      }),
    });
    setDataOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          intersect: false,
          axis: "x",
          backgroundColor: "white",
          titleColor: "black",
          bodyColor: "black",
          padding: 10,
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          titleFont: {
            size: 14,
            weight: "bold",
          },
          bodyFont: {
            size: 12,
          },
          callbacks: {
            title: function (context) {
              return moment(context[0]?.label).format("D MMM YYYY");
            },
            label: function (context) {
              let label = `${context.dataset.label}: ${isCurrencyNumber(
                context.raw
              )} `;
              return `${label}`;
            },
          },
        },
      },
      hover: {
        mode: "index",
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,

            callback: function (value, index, values) {
              const totalDates = values.length;
              if (totalDates >= 100) {
                return index % 6 === 0
                  ? moment(labels[index]).format("MMM YY")
                  : "";
              } else {
                return index % 6 === 0
                  ? moment(labels[index]).format("D MMM")
                  : "";
              }
            },
          },
          grid: {
            display: false,
          },
        },
      },
    });
  };
  const onModifyMarketCap = (data, filterCategoryChartSelected) => {
    const newData = data?.map((item) => {
      return {
        date: item?.dateCreated,
        marketCapital: item?.marketCapital,
      };
    });
    const labels = newData?.map((item) =>
      moment(item.date).format("YYYY-MM-DD")
    );

    setDataSet({
      labels: labels,
      datasets: filterCategoryChartSelected?.children?.map((item) => {
        return {
          type: item?.chart_type,
          label: item?.label,
          data: newData?.map((item_) => item_?.[item?.slug]),
          borderColor: item?.color,
          backgroundColor: item?.color,
          borderWidth: 2,
          pointRadius: 0,
        };
      }),
    });
    setDataOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          intersect: false,
          axis: "x",
          backgroundColor: "white",
          titleColor: "black",
          bodyColor: "black",
          padding: 10,
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          titleFont: {
            size: 14,
            weight: "bold",
          },
          bodyFont: {
            size: 12,
          },
          callbacks: {
            title: function (context) {
              return moment(context[0]?.label).format("D MMM YYYY");
            },
            label: function (context) {
              let label = `${context.dataset.label}: ${isCurrencyNumber(
                context.raw
              )} `;
              return `${label}`;
            },
          },
        },
      },
      hover: {
        mode: "index",
        intersect: false,
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,

            callback: function (value, index, values) {
              const totalDates = values.length;
              if (totalDates >= 100) {
                return index % 6 === 0
                  ? moment(labels[index]).format("MMM YY")
                  : "";
              } else {
                return index % 6 === 0
                  ? moment(labels[index]).format("D MMM")
                  : "";
              }
            },
          },
          grid: {
            display: false,
          },
        },
      },
    });
  };

  const onModifyQuarter = (quarterlyIncome) => {
    // field dates
    const fields = [...quarterlyIncome]
      ?.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
      ?.map((item) => {
        return item?.dateCreated;
      });
    fields.unshift("#");
    setFieldQuarter(fields);

    // sort data quarter
    const data = [...quarterlyIncome]?.sort(
      (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
    );
    setQuarter(data);
  };

  const onModifyAnnualIncome = (annualIncome) => {
    // field dates
    const fields = [...annualIncome]
      ?.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
      .filter((item_) => item_?.dateCreated !== null)
      .map((item) => {
        return item?.dateCreated;
      });
    fields.unshift("#");
    setFieldProfitLoss(fields);

    // sort data quarter
    const data = [...annualIncome]
      ?.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
      .filter((item_) => item_?.dateCreated !== null);
    setProfitLoss(data);
  };

  const onModifyBalanceSheet = (balanceSheet) => {
    // field dates
    const fields = [...balanceSheet]
      ?.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
      ?.map((item) => {
        return item?.dateCreated;
      });
    fields.unshift("#");
    setFieldBalanceSheet(fields);

    // sort data quarter
    const data = [...balanceSheet]?.sort(
      (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
    );
    setBalanceSheet(data);
  };

  const onModifyCashFlow = (cashFlow) => {
    // field dates
    const fields = [...cashFlow]
      ?.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
      ?.map((item) => {
        return item?.dateCreated;
      });
    fields.unshift("#");
    setFieldCashFlow(fields);

    // sort data
    const data = [...cashFlow]?.sort(
      (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
    );
    setCashFlow(data);
  };
  const onModifyRatio = (ratio) => {
    // field dates
    const fields = [...ratio]
      ?.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
      ?.map((item) => {
        return item?.dateCreated;
      });
    fields.unshift("#");
    setFieldRatio(fields);

    // sort data
    const data = [...ratio]?.sort(
      (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
    );
    setRatioData(data);
  };

  const onModifyCompound = (compound) => {
    // SALES GROWTH
    const newCompound = { ...compound };
    setCompoundSalesGrowth(newCompound?.compoundSalesGrowth);
    setDataSetSalesGrowth({
      labels: newCompound?.compoundSalesGrowth?.map((item) => item.period),
      datasets: [
        {
          label: " Sales Growth (%)",
          data: newCompound?.compoundSalesGrowth?.map(
            (item) => item.percentage
          ),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: true,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsSalesGrowth({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });

    // PROFIT GROWTH
    setCompoundProfitGrowth(newCompound?.compoundProfitGrowth);
    setDataSetProfitGrowth({
      labels: newCompound?.compoundProfitGrowth?.map((item) => item.period),
      datasets: [
        {
          label: " Profit Growth (%)",
          data: newCompound?.compoundProfitGrowth?.map(
            (item) => item.percentage
          ),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: true,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsProfitGrowth({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });

    // PRICE
    setCompoundStockPriceCagr(newCompound?.stockPriceCagr);
    setDataSetStockPriceCagr({
      labels: newCompound?.stockPriceCagr?.map((item) => item.period),
      datasets: [
        {
          label: " Price CAGR",
          data: newCompound?.stockPriceCagr?.map((item) => item.percentage),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: true,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsStockPriceCagr({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });

    // ROE
    setReturnOnEquity(newCompound?.returnOnEquity);
    setDataSetReturnOnEquity({
      labels: newCompound?.returnOnEquity?.map((item) => item.period),
      datasets: [
        {
          label: " ROE (%)",
          data: newCompound?.returnOnEquity?.map((item) => item.percentage),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: true,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsReturnOnEquity({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });
  };

  const onModifyShareHoldingPattern = (data) => {
    const filteredData = data.filter((item) => item.percentage > 0);
    setShareHoldingPattern(filteredData);

    const labels = filteredData.map((item) => item.name);

    const percentages = filteredData.map((item) => item.percentage);
    setDataSetShareHoldingPattern({
      labels: labels,
      datasets: [
        {
          label: " Percentage",
          data: percentages,
          backgroundColor: labels.map((item) => isRandomColor()),
        },
      ],
    });

    setDataOptionsShareHoldingPattern({
      responsive: true,
      plugins: {
        legend: {
          position: "right",
          titleFont: {
            color: "white",
          },
          bodyFont: {
            color: "white",
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label || "";
              if (label) {
                label += ": " + context.raw + "%";
              }
              return label;
            },
          },
          titleFont: {
            color: "white",
          },
          bodyFont: {
            color: "white",
          },
        },
        datalabels: {
          display: false,
        },
      },
    });
  };

  const isMinus = (value) => {
    if (value < 0) {
      return true;
    } else {
      return false;
    }
  };

  const toggleTooltip = (slug) => {
    setTooltipOpenPeer((prevState) => ({
      ...prevState,
      [slug]: !prevState[slug],
    }));
  };

  const onShowChild = (slug) =>
    setObjectQuarter(
      objectQuarter.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: true, isOpen: !item.isOpen }
          : item
      )
    );

  const onShowChildPL = (slug) =>
    setObjectProfitLoss(
      objectProfitLoss.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: true, isOpen: !item.isOpen }
          : item
      )
    );
  const onShowChildBalanceSheet = (slug) =>
    setObjectBalanceSheet(
      objectBalanceSheet.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: true, isOpen: !item.isOpen }
          : item
      )
    );

  const onShowChildCashFlow = (slug) =>
    setObjectCashFlow(
      objectCashFlow.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: !item.isBold, isOpen: !item.isOpen }
          : item
      )
    );

  return (
    <div className="detail">
      <MetaHelmet title="Stock Redbel and fundamental" description="Detail" />
      {/* Modal SIDE READ MORE */}
      <CModalSide isOpen={isOpenMore} setIsOpen={() => setIsOpenMore(false)}>
        <ModalHeader>
          <div className="d-flex flex-row gap-2">
            <span className="fw-bold font-large ">{companiesDetail?.name}</span>
            <div className="ms-2" onClick={() => setIsOpenMore(false)}>
              <IoCloseSharp size={20} />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <p className="fw-semibold font-label">
              {t("detail.about")}{" "}
              <span className="font-small text-primary">{"[edit]"}</span>
            </p>
            <span
              className={`${
                state?.darkMode ? "text-white" : "text-secondary"
              } font-label`}
              dangerouslySetInnerHTML={{
                __html: companiesDetail?.about,
              }}
            />
          </div>
          <div>
            <p className="fw-semibold font-label">
              {t("detail.key_point")}{" "}
              <span className="font-small text-primary">{"[edit]"}</span>
            </p>
            <span
              className={`${
                state?.darkMode ? "text-white" : "text-secondary"
              } font-label`}
              dangerouslySetInnerHTML={{
                __html: companiesDetail?.keyPoints,
              }}
            />
          </div>
        </ModalBody>
      </CModalSide>

      {/* Modal Compre */}
      <Modal
        isOpen={isOpenCompre}
        toggle={() => setIsOpenCompre(false)}
        size="lg"
      >
        <ModalHeader toggle={() => setIsOpenCompre(false)} className="border-0">
          Compare {filterCategoryChartSelected?.label} with others
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row gap-4 flex-wrap">
            {peerComparison?.map((item, index) => {
              if (item?.code !== companyIds[0]) {
                return (
                  <label key={index}>
                    <input
                      type="checkbox"
                      checked={companyIds?.includes(item?.code) ? true : false}
                      onChange={() => onCompareCheck(item?.code)}
                    />{" "}
                    {item?.name}
                  </label>
                );
              } else {
                return null;
              }
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setIsOpenCompre(false)}>
            DONE
          </Button>
        </ModalFooter>
      </Modal>
      {loading && <CLoading />}
      {/* Section Summary */}
      <section id="summary">
        <SectionSummary
          companiesDetail={companiesDetail}
          darkMode={state?.darkMode}
          t={t}
          isMinus={isMinus}
          isExpandedAbout={isExpandedAbout}
          setIsExpandedAbout={setIsExpandedAbout}
          setIsOpenMore={setIsOpenMore}
          isOpenMore={isOpenMore}
        />
      </section>

      {/* Section Chart */}
      <section id="chart">
        <SectionChart
          dataSet={dataSet}
          dataOptions={dataOptions}
          filterCategorySelected={filterCategoryChartSelected}
          filterCategory={filterCategoryChart}
          filterDate={filterDateChart}
          filterDateSelected={filterDateChartSelected}
          companyIds={companyIds}
          onSelectCategoryChart={onSelectCategoryChart}
          onSelectDateChart={onSelectDateChart}
          isCompareActive={isCompareActive}
          setIsOpenCompre={setIsOpenCompre}
          isTradingView={isTradingView}
          onSwitchChange={onSwitchChange}
          dataSetTradingView={dataSetTradingView}
          optionsTradingView={optionsTradingView}
          t={t}
          darkMode={state?.darkMode}
        />
      </section>
      {/* Section Analysis */}
      <section id="analysis">
        <SectionAnalys
          companiesDetail={companiesDetail}
          t={t}
          darkMode={state?.darkMode}
        />
      </section>

      {/* Section Compound */}
      <Row className="mt-4">
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">
                  {t("detail.SALESGROWTH")} %
                </p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line data={dataSetSalesGrowth} options={optionsSalesGrowth} />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {compoundSalesGrowth?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>

                    <div className="fw-bold font-label">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">
                  {t("detail.PROFITGROWTH")} %
                </p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line
                  data={dataSetProfitGrowth}
                  options={optionsProfitGrowth}
                />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {compoundProfitGrowth?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>
                    {/* Display percentage */}
                    <div className="fw-bold font-label">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">{t("detail.PRICECAGR")} %</p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line
                  data={dataSetStockPriceCagr}
                  options={optionsStockPriceCagr}
                />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {compoundstockPriceCagr?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>
                    {/* Display percentage */}
                    <div className="fw-bold font-label">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">ROE %</p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line
                  data={dataSetReturnOnEquity}
                  options={optionsReturnOnEquity}
                />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {returnOnEquity?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>
                    {/* Display percentage */}
                    <div className="fw-bold font-label">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Section Peer comparison */}
      <section id="peers">
        <SectionPeer
          companiesDetail={companiesDetail}
          t={t}
          darkMode={state.darkMode}
          fieldPeerComparison={fieldPeerComparison}
          tooltipOpenPeer={tooltipOpenPeer}
          toggleTooltip={toggleTooltip}
          peerComparison={peerComparison}
        />
      </section>

      {/* Section QuarterResult */}
      <section id="quarters">
        <SectionQuarter
          t={t}
          darkMode={state.darkMode}
          quarter={quarter}
          fieldQuarter={fieldQuarter}
          objectQuarter={objectQuarter}
          onShowChild={onShowChild}
        />
      </section>
      {/* Section Profit Loss */}
      <section id="profitLoss">
        <SectionProfitLoss
          t={t}
          darkMode={state.darkMode}
          profitLoss={profitLoss}
          fieldProfitLoss={fieldProfitLoss}
          objectProfitLoss={objectProfitLoss}
          onShowChildPL={onShowChildPL}
        />
      </section>
      {/* Section Balance Sheet */}
      <section id="balanceSheet">
        <SectionBalance
          t={t}
          balanceSheet={balanceSheet}
          fieldBalanceSheet={fieldBalanceSheet}
          objectBalanceSheet={objectBalanceSheet}
          darkMode={state.darkMode}
          onShowChildBalanceSheet={onShowChildBalanceSheet}
        />
      </section>
      {/* Section Cash Flows */}
      <section id="cashFlow">
        <SectionCashFlow
          t={t}
          darkMode={state.darkMode}
          cashFlow={cashFlow}
          fieldCashFlow={fieldCashFlow}
          objectCashFlow={objectCashFlow}
          onShowChildCashFlow={onShowChildCashFlow}
        />
      </section>
      {/* Section Ratio */}
      <section id="ratios">
        <SectionRatio
          ratio={ratio}
          t={t}
          darkMode={state.darkMode}
          fieldRatio={fieldRatio}
          objectRatio={objectRatio}
          ratioData={ratioData}
        />
      </section>

      {/* Section Shareholding */}
      <section id="investors">
        <SectionShareholding
          dataSetShareHoldingPattern={dataSetShareHoldingPattern}
          dataOptionsShareHoldingPattern={dataOptionsShareHoldingPattern}
          t={t}
          darkMode={state.darkMode}
          shareHoldingPattern={shareHoldingPattern}
        />
      </section>
    </div>
  );
};

export default Company;
