import React, { useContext, useEffect, useRef, useState } from "react";

import { useMutation } from "@apollo/client";
import { GoogleLogin } from "@react-oauth/google";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Button, Col, Label, Row } from "reactstrap";
import { BannerLogin } from "../../../assets/img";
import CInput from "../../../components/CInput";
import CLoading from "../../../components/CLoading";
import { GlobalContext } from "../../../contexts/GlobalContext";
import AuthGoogle from "../../../graphQL/Mutation/AuthGoogle";
import RegisterManual from "../../../graphQL/Mutation/RegisterManual";
import formValidation from "../../../utils/formValidation";

const Register = () => {
  const alert = useAlert();
  const { t } = useTranslation("global");
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });

  const [error, setError] = useState({
    email: false,
    password: false,
    confirm_password: false,
  });

  const divRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.getBoundingClientRect().width);
    }

    const handleResize = () => {
      if (divRef.current) {
        setWidth(divRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [authGoogle, { loading }] = useMutation(AuthGoogle, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.googleAuth?.token) {
        secureLocalStorage.setItem("token", data?.googleAuth?.token);
        secureLocalStorage.setItem(
          "user",
          JSON.stringify(data?.googleAuth?.user)
        );

        window.location.href = "/dashboard";
      }
    },
    onError: ({ networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
          return alert.error(message);
        });
      }
      if (networkError) {
        alert.error(networkError?.result?.error);
      }
    },
  });

  const [AuthManual, { loading: loadingManually }] = useMutation(
    RegisterManual,
    {
      fetchPolicy: "network-only",
      onCompleted: (data_) => {
        console.log(data_);
        if (data_?.registration?.status) {
          navigate("/register/verify", {
            state: { email: data?.email },
          });
        } else {
          alert.error(data_?.registration?.error);
        }
      },

      onError: ({ networkError, graphQLErrors }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message }) => {
            return alert.error(message);
          });
        }
        if (networkError) {
          alert.error(networkError?.result?.error);
        }
      },
    }
  );

  const responseMessage = (response) => {
    authGoogle({ variables: { token: response.credential } });
  };
  const errorMessage = (error) => {
    alert.error("Register Failed, please try again");
  };

  const onChangeText = (e) => {
    const validate = formValidation(e.target.name, e.target.value);
    setError({
      ...error,
      [e.target.name]: !validate,
    });
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    const newData = { ...data };
    let errors = 0;
    for (let i in newData) {
      const name = i;
      const value = newData[i];
      const validate = formValidation(name, value);
      if (!validate) {
        errors = errors + 1;
      }
      setError((prevState) => ({
        ...prevState,
        [name]: !validate,
      }));
    }
    if (errors > 0) {
      alert.error("Please fill all fields");
      return;
    }
    if (data.password !== data.confirm_password) {
      alert.error(t("reset_password_rule.password_not_match"));
      setError({
        ...error,
        confirm_password: true,
      });
      return;
    }

    AuthManual({
      variables: {
        email: data.email,
        password: data.password,
      },
    });
  };

  return (
    <div style={{ height: "90vh" }}>
      {loading || loadingManually ? <CLoading /> : null}

      <Row>
        <Col sm="12" md="8" lg="8" className="d-none d-lg-block">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "90vh" }}
          >
            <img
              src={BannerLogin}
              alt="login"
              style={{
                height: "80vh",
              }}
            />
          </div>
        </Col>
        <Col
          sm="12"
          md="12"
          lg="4"
          className={`px-4 ${state.darkMode ? "bg-dark" : "bg-white"}`}
        >
          <div
            ref={divRef}
            className={`${
              state.darkMode ? "bg-dark" : "bg-white"
            }  d-flex pt-3 justify-content-center`}
            style={{ height: "90vh" }}
          >
            <div>
              <div className="d-flex flex-column mb-3">
                <span className="font-extra-large fw-semibold">
                  {t("register.title")}
                </span>
                <span className="text-secondary font-title">
                  {t("register.subtitle")}
                </span>
              </div>

              <div>
                <div className="d-flex ">
                  <div className="d-flex flex-column w-100 gap-3">
                    <div>
                      <Label for="email">Email</Label>
                      <CInput
                        name="email"
                        type={"email"}
                        value={data?.email}
                        placeholder={t("login.enter_email")}
                        onChange={onChangeText}
                      />
                      {error?.email && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {t("forgot_password.valid_email")}
                        </span>
                      )}
                    </div>
                    <div>
                      <Label for="password">{t("login.password")}</Label>
                      <CInput
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={data?.password}
                        placeholder={t("login.enter_password")}
                        onChange={onChangeText}
                        rightIcon={
                          !showPassword ? (
                            <FaRegEyeSlash width={20} height={20} />
                          ) : (
                            <FaRegEye />
                          )
                        }
                        rightAction={() => setShowPassword(!showPassword)}
                      />
                      {error?.password && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {t("forgot_password.valid_password")}
                        </span>
                      )}
                    </div>
                    <div>
                      <Label for="conrfirm_password">
                        {t("login.confirm_password")}
                      </Label>
                      <CInput
                        name="confirm_password"
                        type={showPasswordConfirm ? "text" : "password"}
                        value={data?.confirm_password}
                        placeholder={t("login.enter_confirm_password")}
                        onChange={onChangeText}
                        rightIcon={
                          !showPasswordConfirm ? (
                            <FaRegEyeSlash width={20} height={20} />
                          ) : (
                            <FaRegEye />
                          )
                        }
                        rightAction={() =>
                          setShowPasswordConfirm(!showPasswordConfirm)
                        }
                      />
                      {error?.confirm_password && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {t("reset_password_rule.password_not_match")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <label
                    className="text-secondary mb-3"
                    style={{ fontSize: 14 }}
                  >
                    {t("register.register_aggre")}{" "}
                    <NavLink className="text-primary text-decoration-none">
                      {t("register.terms_use")}
                    </NavLink>{" "}
                    {t("register.and")}{" "}
                    <NavLink className="text-primary text-decoration-none">
                      {t("register.privacy_policy")}
                    </NavLink>{" "}
                  </label>
                  <Button
                    color="primary shadow-none w-100 p-2 "
                    onClick={onSubmit}
                  >
                    <span className="fw-bold">
                      {t("register.create_account")}
                    </span>
                  </Button>
                </div>
                <div className="py-3 text-center">
                  <span className="text-secondary font-description">
                    {t("login.or_using_email")}
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  <GoogleLogin
                    width={width}
                    onSuccess={responseMessage}
                    onError={errorMessage}
                    size="large"
                    text="signup_with"
                    type="standard"
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
