import moment from "moment";
import React from "react";
import { Card, CardBody, Table } from "reactstrap";
import { NoData } from "../../../assets/img";
import CEmpty from "../../../components/CEmpty";
import { isCurrencyWithOutRp } from "../../../utils/isCurrency";
import IsMobile from "../../../utils/IsMobile";

const SectionRatio = ({
  ratio,
  fieldRatio,
  objectRatio,
  ratioData,
  darkMode,
  t,
}) => {
  const mobileView = IsMobile();
  return (
    <Card className="mt-4 shadow-sm">
      <CardBody>
        <div className="d-flex flex-column">
          <span className="font-extra-large fw-bold">{t("detail.ratio")}</span>
          <span className="text-secondary font-title">
            {t("detail.standalone_figures")}
            <span
              className="text-primary"
              style={{ fontSize: mobileView ? "14px" : "16px" }}
            >
              {" "}
              / {t("detail.view_consolidated")}
            </span>
          </span>
        </div>
        {ratioData?.length > 0 ? (
          <div className="table-container">
            <Table className={`detail-table ${darkMode && "table-dark"}`}>
              <thead>
                <tr className="quarter-tr">
                  {fieldRatio.map((date, index) => (
                    <th
                      key={index}
                      style={{
                        fontWeight: "500",
                        fontSize: mobileView ? "10px" : "12px",
                      }}
                    >
                      {date.includes("#")
                        ? ""
                        : moment(date).format("MMM YYYY")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {objectRatio.map((itemObj, indexObj) => (
                  <tr key={indexObj} className={"quarter-tr"}>
                    <td
                      style={{
                        cursor: "pointer",
                        fontWeight: itemObj?.isBold ? "bold" : "normal",
                        fontSize: mobileView ? "10px" : "12px",
                        backgroundColor: itemObj?.isBold
                          ? darkMode
                            ? " #2d2d2d"
                            : "white"
                          : darkMode
                          ? "#14161f"
                          : "#f5f5f5",
                      }}
                    >
                      {itemObj?.label}
                    </td>
                    {ratioData.map((itemValue, indexValue) => (
                      <td
                        key={indexValue}
                        style={{
                          backgroundColor: itemObj?.isBold
                            ? darkMode
                              ? " #2d2d2d"
                              : "white"
                            : darkMode
                            ? "#14161f"
                            : "#f5f5f5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: mobileView ? "10px" : "12px",
                            fontWeight: itemObj?.isBold ? "bold" : "normal",
                          }}
                        >
                          {itemObj?.label?.includes("%")
                            ? itemValue[itemObj?.slug]?.toFixed(2)
                            : isCurrencyWithOutRp(
                                itemValue[itemObj?.slug]
                              )}{" "}
                          {itemObj?.label?.includes("%") ? "%" : ""}
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <CEmpty
            className="d-flex justify-content-center align-items-center flex-column"
            image={<img src={NoData} alt="No Data" width={250} />}
            title={t("detail.no_data")}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default SectionRatio;
