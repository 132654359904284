import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CiCircleRemove } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import {
  IoChevronBackCircleOutline,
  IoChevronForwardCircleOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Carousel,
  CarouselItem,
  Col,
  Container,
  Row,
} from "reactstrap";
import CInput from "../../components/CInput";
import CLoading from "../../components/CLoading";
import QueryNews from "../../graphQL/Query/QueryNews";
import IsMobile from "../../utils/IsMobile";

const News = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const [news, setNews] = useState([]);
  const [newsHeadline, setNewsHeadline] = useState([]);
  const [loadMore, setLoadMore] = useState({
    page: 1,
    limit: 8,
    end: false,
  });
  const [search, setSearch] = useState("");
  const mobileView = IsMobile();

  const [fetchNews, { loading }] = useLazyQuery(QueryNews, {
    onCompleted: (data) => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // untuk scroll halus
      });
      if (data?.news?.length > 0) {
        if (loadMore.page === 1) {
          const filteredHeadline = data.news.filter(
            (item) => item.isHeadline === true || item.isHeadline === "true"
          );
          setNewsHeadline(filteredHeadline);
          setNews(data?.news);
        } else {
          setNews(data?.news);
        }
      } else {
        setLoadMore({ ...loadMore, end: true });
      }
    },
    onError: ({ networkError, graphQLErrors }) => {
      console.log(networkError, graphQLErrors);
    },
  });

  useEffect(() => {
    fetchNews({
      variables: {
        filter: "",
        limit: 8,
        page: 1,
      },
    });

    // eslint-disable-next-line
  }, []);

  // State untuk Carousel
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  // Handler Carousel
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === newsHeadline?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? newsHeadline?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = newsHeadline.map((headline, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
      interval={5000}
      onClick={() => onDetailNews(headline)}
    >
      <div className="carousel-image-wrapper">
        <img
          src={headline?.bannerUrl}
          alt={`Headline ${index + 1}`}
          className="carousel-image"
        />
        <div className="carousel-caption d-flex flex-column">
          <span className="font-label fw-semibold">
            {moment(headline?.dateCreated).format("DD MMMM YYYY")}
          </span>
          <span className="font-title fw-bold news-title">
            {headline?.title}
          </span>
        </div>
      </div>
    </CarouselItem>
  ));

  const handleNext = () => {
    setLoadMore({ ...loadMore, page: loadMore.page + 1 });
    fetchNews({
      variables: {
        filter: "",
        limit: 8,
        page: loadMore.page + 1,
      },
    });
  };

  const handlePrevious = () => {
    setLoadMore({ ...loadMore, page: loadMore.page - 1 });
    fetchNews({
      variables: {
        filter: "",
        limit: 8,
        page: loadMore.page - 1,
      },
    });
  };

  const onDetailNews = (data) => {
    navigate(`/news/${data?.id}`, {
      state: {
        news: data,
        otherNews: news?.slice(0, 3),
      },
    });
  };

  return (
    <Container>
      {loading && <CLoading />}
      {/* Headline Section */}
      <div className="my-2">
        <span className="font-extra-large fw-bold">{t("news.headline")}</span>
      </div>

      <Row className="my-4">
        <Col lg="6" sm="12" md="8">
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            {slides}
          </Carousel>
        </Col>
        <Col lg="6" sm="12" md="4">
          {newsHeadline.map(
            (headline, index) =>
              index < 4 && (
                <div
                  key={index}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => onDetailNews(headline)}
                >
                  <Card className="d-flex flex-row gap-2 mt-sm-2 mt-2 mt-lg-0 mb-lg-2 mb-0 shadow-sm">
                    <div className="col-lg-3 col-md-3 col-3">
                      <CardImg
                        height={"100px"}
                        width={"100px"}
                        style={{
                          objectFit: "cover",
                        }}
                        src={headline?.bannerUrl}
                        alt={`Headline ${index + 1}`}
                      />
                    </div>
                    <div className="py-2">
                      <CardTitle className="font-description ">
                        {moment(headline?.dateCreated).format("DD MMMM YYYY")}
                      </CardTitle>
                      <CardTitle className="font-label news-title">
                        {headline?.title}
                      </CardTitle>
                    </div>
                  </Card>
                </div>
              )
          )}
        </Col>
      </Row>
      <div className="my-2 d-flex justify-content-between flex-column flex-lg-row">
        <span className="font-extra-large fw-bold">{t("news.all_news")}</span>
        <div>
          <CInput
            placeholder={t("news.search_news")}
            widthInput={mobileView ? "100%" : "300px"}
            heightInput={"40px"}
            borderColor={"border-primary"}
            leftIcon={<FaSearch size={14} />}
            rightIcon={
              search?.length > 0 && <CiCircleRemove size={14} color="red" />
            }
            rightAction={() => {
              setSearch("");
              fetchNews({
                variables: {
                  filter: "",
                  limit: 12,
                  page: 1,
                },
              });
            }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                fetchNews({
                  variables: {
                    filter: search,
                    limit: 12,
                    page: 1,
                  },
                });
              }
            }}
          />
        </div>
      </div>
      <Row>
        {news.map((news, index) => (
          <Col md="3" lg="3" sm="3" className="mb-4" key={index}>
            <Card
              onClick={() => onDetailNews(news)}
              style={{ cursor: "pointer" }}
            >
              <CardImg
                src={news?.bannerUrl}
                height={"200px"}
                style={{
                  objectFit: "cover",
                }}
                alt={`News ${index + 1}`}
              />
              <CardBody>
                <div className=" d-flex flex-column">
                  <span className="font-description ">
                    {moment(news?.dateCreated).format("DD MMMM YYYY")}
                  </span>
                  <span className="font-label news-title">{news?.title}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="d-flex flex-row justify-content-between">
        {loadMore.page > 1 && (
          <div
            className="d-flex flex-row gap-2 align-items-center"
            style={{ cursor: "pointer" }}
          >
            <IoChevronBackCircleOutline size={25} onClick={handlePrevious} />
            <span className="font-title">{t("news.previous")}</span>
          </div>
        )}

        <div>
          <span className="font-title">
            {t("news.page")} : {loadMore.page}
          </span>
        </div>
        {!loadMore?.end && (
          <div
            className="d-flex flex-row gap-2 align-items-center"
            style={{ cursor: "pointer" }}
          >
            <span className="font-title">{t("news.next")}</span>
            <IoChevronForwardCircleOutline size={25} onClick={handleNext} />
          </div>
        )}
      </div>
    </Container>
  );
};

export default News;
