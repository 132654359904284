import { gql } from "@apollo/client";

const QueryNews = gql`
  query News($filter: String, $limit: Int, $page: Int) {
    news(filter: $filter, limit: $limit, page: $page) {
      id
      dateCreated
      externalId
      externalItemId
      bannerUrl
      locale
      title
      subtitle
      tags
      isHeadline
      content
    }
  }
`;
export default QueryNews;
