import { gql } from "@apollo/client";

const QueryCompareSector = gql`
  query CompareSector($sector: String) {
    company(sector: $sector) {
      id
      code
      name
      primarySector
      industry
      ratio {
        priceToEarning
        marketCapital
        dividendYield
      }
      annualIncome {
        returnOnCapitalEmployedLatestPeriod
      }
      quarterlyIncome {
        netProfitLatestPeriod
        yoyPeriodSalesGrowthLatestPeriod
        yoyPeriodProfitGrowthLatestPeriod
        yoyPeriodSalesGrowthLatestPeriod
        salesLatestPeriod
      }
      stockSummaries {
        price
      }
    }
  }
`;

export default QueryCompareSector;
