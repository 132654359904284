import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle, Label, Spinner } from "reactstrap";
import CInput from "../../../components/CInput";
import ResetPasswordNew from "../../../graphQL/Mutation/ResetPassword";

const ResetPassword = () => {
  const params = new URLSearchParams(useLocation().search);
  const code = params.get("c");
  const user = params.get("u");
  const { t } = useTranslation("global");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const alert = useAlert();
  const navigate = useNavigate();

  const [codeValue] = useState(code ? code : "");
  const [userValue] = useState(user ? atob(user) : "");

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);

  const [resetPassword, { loading }] = useMutation(ResetPasswordNew, {
    onCompleted: (data) => {
      if (data?.resetPassword?.status) {
        navigate("/login");
      }
    },
    onError: (error) => {
      alert.error("Failed to reset password");
    },
  });
  const onSubmit = () => {
    const { password, confirmPassword } = { ...data };
    if (!password || !confirmPassword) {
      setErrorMessage(t("reset_password_rule.two"));
    } else if (password.length < 8 || confirmPassword.length < 8) {
      setErrorMessage(t("reset_password_rule.two"));
    } else if (password !== confirmPassword) {
      setErrorMessage(t("reset_password_rule.password_not_match"));
    } else {
      setErrorMessage("");
      resetPassword({
        variables: {
          code: codeValue,
          email: userValue,
          password: password,
        },
      });
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <Card style={{ width: "650px" }} className="p-3">
        <CardTitle>
          <div>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              {t("forgot_password.reset_password")}
            </span>
          </div>
        </CardTitle>
        <CardBody>
          <div className="border border-dark px-2 py-1 rounded">
            <ul>
              <li>
                <span style={{ fontSize: "14px" }}>
                  {t("reset_password_rule.one")}
                </span>
              </li>
              <li>
                <span style={{ fontSize: "14px" }}>
                  {t("reset_password_rule.two")}
                </span>
              </li>
              <li>
                <span style={{ fontSize: "14px" }}>
                  {t("reset_password_rule.three")}
                </span>
              </li>
              <li>
                <span style={{ fontSize: "14px" }}>
                  {t("reset_password_rule.four")}
                </span>
              </li>
            </ul>
          </div>
          {errorMessage && (
            <div className="px-2 bg-danger py-1 my-1 rounded">
              <span style={{ fontSize: "14px", color: "white" }}>
                {errorMessage}
              </span>
            </div>
          )}

          <div className="my-3">
            <Label>{t("login.password")}</Label>
            <CInput
              type={showPassword ? "text" : "password"}
              value={data?.password}
              placeholder={t("login.password")}
              rightIcon={
                !showPassword ? (
                  <FaRegEyeSlash width={20} height={20} />
                ) : (
                  <FaRegEye />
                )
              }
              rightAction={() => setShowPassword(!showPassword)}
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
          </div>
          <div className="my-3">
            <Label>{t("login.confirm_password")}</Label>
            <CInput
              type={showConfirmPassword ? "text" : "password"}
              value={data?.confirmPassword}
              placeholder={t("login.confirm_password")}
              rightIcon={
                !showConfirmPassword ? (
                  <FaRegEyeSlash width={20} height={20} />
                ) : (
                  <FaRegEye />
                )
              }
              rightAction={() => setShowConfirmPassword(!showConfirmPassword)}
              onChange={(e) =>
                setData({ ...data, confirmPassword: e.target.value })
              }
            />
          </div>
          <div className="my-3">
            <button className="btn btn-primary" onClick={onSubmit}>
              {loading ? (
                <Spinner size="sm" />
              ) : (
                t("forgot_password.reset_password")
              )}
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ResetPassword;
