import { ApolloProvider } from "@apollo/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import i18next from "i18next";
import React from "react";
import { Provider as AlertProvider, positions, transitions } from "react-alert";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import CAlert from "./components/CAlert";
import { GlobalProvider } from "./contexts/GlobalContext";
import client from "./graphQL/ApolloClient";
import "./index.css";
import en_lang from "./locales/en/en.json";
import id_lang from "./locales/id/id.json";
import reportWebVitals from "./reportWebVitals";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("language") || "id",
  fallbackLng: "id",
  resources: {
    en: {
      global: en_lang,
    },
    id: {
      global: id_lang,
    },
  },
});

const options = {
  position: positions.TOP_RIGHT,
  timeout: 3000,
  transition: transitions.SCALE,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <I18nextProvider i18n={i18next}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AlertProvider template={CAlert} {...options}>
          <GlobalProvider>
            <App />
          </GlobalProvider>
        </AlertProvider>
      </GoogleOAuthProvider>
    </I18nextProvider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
