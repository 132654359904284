import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import CLoading from "../../components/CLoading";
import QueryRank from "../../graphQL/Query/QueryRank";
import ChartMarket from "./chart/ChartMarket";

const Market = () => {
  const [stockRank, setStockRank] = useState([]);
  const [volumeRank, setVolumeRank] = useState([]);
  const [gainerRank, setGainerRank] = useState([]);
  const [frequentRank, setFrequentRank] = useState([]);

  const [fetchData, { loading }] = useLazyQuery(QueryRank);

  useEffect(() => {
    onFetchStockRank();
    onFetchVolumeRank();
    onFetchGainerRank();
    onFetchFrequentRank();
    // eslint-disable-next-line
  }, []);

  const onFetchStockRank = async () => {
    const result = await fetchData({
      variables: {
        type: "STOCK_RANK",
        limit: 5,
      },
    });

    if (result?.data?.rank) {
      const data = result?.data?.rank?.filter(
        (item) => item?.stockSummaries?.length > 0
      );
      setStockRank(data);
    }
  };
  const onFetchVolumeRank = async () => {
    const result = await fetchData({
      variables: {
        type: "VOLUME_RANK",
        limit: 5,
      },
    });
    if (result?.data?.rank) {
      const data = result?.data?.rank?.filter(
        (item) => item?.stockSummaries?.length > 0
      );
      setVolumeRank(data);
    }
  };

  const onFetchGainerRank = async () => {
    const result = await fetchData({
      variables: {
        type: "GAINER_RANK",
        limit: 5,
      },
    });
    if (result?.data?.rank) {
      const data = result?.data?.rank?.filter(
        (item) => item?.stockSummaries?.length > 0
      );
      setGainerRank(data);
    }
  };

  const onFetchFrequentRank = async () => {
    const result = await fetchData({
      variables: {
        type: "FREQUENT_RANK",
        limit: 5,
      },
    });
    if (result?.data?.rank) {
      const data = result?.data?.rank?.filter(
        (item) => item?.stockSummaries?.length > 0
      );
      setFrequentRank(data);
    }
  };
  return (
    <div>
      {loading && <CLoading />}
      <div className="d-flex flex-column mb-2">
        <span className="font-extra-large fw-semibold">Stock Market Today</span>
        <span className="font-label">
          See top gainers, volume stockers, top frequent & top stock
        </span>
      </div>
      <Row>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={stockRank}
            value="price"
            title="TOP STOCK"
            name="Day Price"
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={volumeRank}
            value="volume"
            title="VOLUME STOCKERS"
            name="Day Volume"
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={gainerRank}
            value="change"
            title="TOP GAINER STOCK"
            name="Week Volume"
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={frequentRank}
            value="tradeFrequency"
            title="TRADE FREQUENT"
            name="Day Frequency"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Market;
