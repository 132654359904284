import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from "reactstrap";
import CInput from "../../../components/CInput";
import ResendRegistrationEmail from "../../../graphQL/Mutation/ResendRegistrationEmail";
import VerifyRegistration from "../../../graphQL/Mutation/VerifyRegistration";
import HideEmail from "../../../utils/hideMail";

const VerifyAccount = () => {
  const props = useLocation();
  const [email] = useState(props.state.email);
  const alert = useAlert();
  const [code, setCode] = useState("");
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(30);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (timeLeft === 0) return;
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  const [verifyEmail, { loading }] = useMutation(VerifyRegistration, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.verifyRegistration?.status) {
        alert.success("Account verified successfully, you can login now");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        setErrorMessage(data?.verifyRegistration?.error);
        alert.error(data?.verifyRegistration?.error);
      }
    },

    onError: ({ networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
          return alert.error(message);
        });
      }
      if (networkError) {
        alert.error(networkError?.result?.error);
      }
    },
  });

  const [resendCode] = useMutation(ResendRegistrationEmail, {
    fetchPolicy: "network-only",
    variables: {
      email: email,
    },
    onCompleted: (data) => {
      if (data?.resendRegistrationEmail?.status) {
        alert.success("Code sent successfully");
      } else {
        alert.error(data?.resendRegistrationEmail?.error);
      }
    },
    onError: ({ networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
          return alert.error(message);
        });
      }
      if (networkError) {
        alert.error(networkError?.result?.error);
      }
    },
  });

  const onVerify = () => {
    if (!code) {
      setErrorMessage("Please enter code");
      return false;
    }

    verifyEmail({
      variables: {
        code: code,
      },
    });
  };

  return (
    <div className="d-flex justify-content-center ">
      <Card style={{ width: "650px" }}>
        <CardHeader className="bg-white border-0">
          <CardTitle>
            <div
              className="d-flex flex-row gap-3"
              onClick={() => navigate("/register")}
              style={{ cursor: "pointer" }}
            >
              <div>
                <BsArrowLeft size={20} />
              </div>
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {t("verify_email.title")}
                </span>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <span style={{ fontSize: "14px" }}>
            {t("verify_email.subtitle")}{" "}
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              {HideEmail(email || "")}
            </span>
            <div className="mt-1">
              <span style={{ fontSize: "14px" }}>
                {t("verify_email.description")}
              </span>
            </div>
          </span>
          {errorMessage && (
            <div className="px-2 bg-danger py-1 my-1 rounded">
              <span style={{ fontSize: "14px", color: "white" }}>
                {errorMessage}
              </span>
            </div>
          )}
          <div className="mt-2">
            <CInput
              name="code"
              value={code}
              placeholder={t("verify_email.enter_code")}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <Button
              disabled={loading}
              color="primary"
              className="w-100"
              onClick={onVerify}
            >
              {loading ? <Spinner size="sm" /> : t("verify_email.verify")}
            </Button>
          </div>
          <div className="mt-1">
            {timeLeft === 0 ? (
              <div
                style={{ cursor: "pointer" }}
                className="py-2 text-center"
                onClick={() => {
                  setTimeLeft(30);
                  resendCode();
                }}
              >
                <span
                  className="text-primary "
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("forgot_password.resend")}
                </span>
              </div>
            ) : (
              <span style={{ fontSize: "14px" }}>
                {t("verify_email.resend")}{" "}
                <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {timeLeft} {t("verify_email.seconds")}
                </span>
              </span>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default VerifyAccount;
