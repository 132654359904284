import { useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import CLoading from "../../components/CLoading";
import { GlobalContext } from "../../contexts/GlobalContext";
import Sectors from "../../graphQL/Query/Sectors";

const Screens = () => {
  const { t } = useTranslation("global");
  const { state } = useContext(GlobalContext);
  const [sectors, setSectors] = useState([]);
  const navigate = useNavigate();

  const { loading } = useQuery(Sectors, {
    onCompleted: (data) => {
      setSectors(data?.sector);
    },
  });

  return (
    <div>
      {loading && <CLoading />}
      <Row>
        <Col sm="12" lg="8" md="8">
          <Card>
            <CardBody>
              <div>
                <p className="font-large fw-bold">
                  {t("screen.popular_themes")}
                </p>
              </div>
              <div>
                <Row className="gap-sm-3 gap-3 gap-lg-0">
                  <Col sm="6" lg="4" md="6">
                    <div className="border rounded p-2 d-flex flex-column">
                      <span className="font-label fw-bold">
                        Low on 10 year avarage earnings
                      </span>
                      <span className="font-description text-truncate">
                        Graham liked to value stocks based on average earnings
                        of multiple years. This screen uses 10 year average
                        earnings.
                      </span>
                    </div>
                  </Col>
                  <Col sm="6" lg="4" md="6">
                    <div className="border rounded p-2 d-flex flex-column">
                      <span className="font-label fw-bold">
                        Low on 10 year avarage earnings
                      </span>
                      <span className="font-description text-truncate">
                        Graham liked to value stocks based on average earnings
                        of multiple years. This screen uses 10 year average
                        earnings.
                      </span>
                    </div>
                  </Col>
                  <Col sm="6" lg="4" md="6">
                    <div className="border rounded p-2 d-flex flex-column">
                      <span className="font-label fw-bold">
                        Low on 10 year avarage earnings
                      </span>
                      <span className="font-description text-truncate">
                        Graham liked to value stocks based on average earnings
                        of multiple years. This screen uses 10 year average
                        earnings.
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" lg="4" md="4">
          <Card>
            <CardBody>
              <div>
                <p className="font-large fw-bold">
                  {t("screen.browse_sector")}
                </p>
              </div>
              <div className="d-flex flex-row flex-wrap gap-3 justify-content-start align-items-center">
                {sectors?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      navigate(`/sector/compare/${item.toLowerCase()}`)
                    }
                    style={{ cursor: "pointer" }}
                    className={`border p-1 font-label rounded card-search ${
                      state?.darkMode
                        ? "bg-darkMode text-light"
                        : "bg-brown text-dark"
                    }`}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Screens;
