import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from "reactstrap";
import RequestResetPassword from "../../../graphQL/Mutation/RequestResetPassword";
import HideEmail from "../../../utils/hideMail";

const ForgotPasswordDone = () => {
  const props = useLocation();
  const navigate = useNavigate();
  const alert = useAlert();
  const { t } = useTranslation("global");
  const [timeLeft, setTimeLeft] = useState(30);

  const [reqResetPassword, { loading }] = useMutation(RequestResetPassword, {
    onCompleted: (data) => {
      if (data?.equestResetPassword?.status) {
        navigate("/forgot-password/done", {
          state: { email: props.state.email },
        });
      }
      setTimeLeft(30);
      alert.success("Reset password link has been sent to your email");
    },
    onError: () => {
      setTimeLeft(30);
      alert.error("Failed to reset password, please try again");
    },
  });

  useEffect(() => {
    if (timeLeft === 0) return;
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  const onHandleSubmit = (e) => {
    reqResetPassword({
      variables: {
        email: props.state.email,
      },
    });
  };

  return (
    <div className="d-flex justify-content-center ">
      <Card style={{ width: "650px" }}>
        <CardHeader className="bg-white border-0">
          <CardTitle>
            <div
              className="d-flex flex-row gap-3"
              onClick={() => navigate("/login")}
              style={{ cursor: "pointer" }}
            >
              <div>
                <BsArrowLeft size={20} />
              </div>
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {t("forgot_password.reset_password")}
                </span>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <span style={{ fontSize: "14px" }}>
            {t("forgot_password.check_email_desc")}{" "}
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              {HideEmail(props?.state?.email || "")}
            </span>
          </span>
          <label className="text-secondary" style={{ fontSize: "12px" }}>
            {t("forgot_password.description_resend")}
          </label>
          {timeLeft > 0 ? (
            <div className="my-2">
              <span style={{ fontSize: "12px" }}>
                {t("forgot_password.resend")} link ?
              </span>
              <h2 className="text-success text-center">{timeLeft} </h2>
            </div>
          ) : (
            <Button
              color="success"
              className="w-100 mt-2"
              onClick={onHandleSubmit}
            >
              {loading ? <Spinner size="sm" /> : t("forgot_password.resend")}
            </Button>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ForgotPasswordDone;
