import { useLazyQuery, useMutation } from "@apollo/client";
import { GoogleLogin } from "@react-oauth/google";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { FaRegEye, FaRegEyeSlash, FaUser } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Button, Col, Row } from "reactstrap";
import { BannerLogin } from "../../../assets/img";
import CInput from "../../../components/CInput";
import CLoading from "../../../components/CLoading";
import { GlobalContext } from "../../../contexts/GlobalContext";
import AuthGoogle from "../../../graphQL/Mutation/AuthGoogle";
import AuthManually from "../../../graphQL/Mutation/AuthManually";
import QueryProfile from "../../../graphQL/Query/QueryProfile";
import formValidation from "../../../utils/formValidation";

const Login = () => {
  const { t } = useTranslation("global");
  const { state } = useContext(GlobalContext);

  const alert = useAlert();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  // Variable for login manually
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({
    username: false,
    password: false,
  });
  const divRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.getBoundingClientRect().width);
    }

    const handleResize = () => {
      if (divRef.current) {
        setWidth(divRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Mutation Login Google
  const [authGoogle, { loading }] = useMutation(AuthGoogle, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.googleAuth?.token) {
        secureLocalStorage.setItem("token", data?.googleAuth?.token);
        secureLocalStorage.setItem(
          "user",
          JSON.stringify(data?.googleAuth?.user)
        );

        fetchDashboard({
          context: {
            headers: {
              Authorization: `Bearer ${data?.googleAuth?.token}`,
            },
          },
        });
      }
    },
    onError: ({ networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
          return alert.error(message);
        });
      }
      if (networkError) {
        alert.error(networkError?.result?.error);
      }
    },
  });

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const [fetchDashboard, { loadingDashboard }] = useLazyQuery(QueryProfile, {
    onCompleted: (data) => {
      const newDashboard = data?.profile?.userProfiles?.dashboard.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.section]: {
              ...acc[curr.section],
              [curr.value]: true,
            },
          };
        },
        {}
      );
      if (!isEmptyObject(newDashboard)) {
        secureLocalStorage.setItem(
          "dashboard_obj",
          JSON.stringify(newDashboard)
        );
        secureLocalStorage.setItem(
          "dashboard_arr",
          JSON.stringify(data?.profile?.userProfiles?.dashboard)
        );
      }

      window.location.href = "/dashboard";
    },
  });

  const [AuthManual, { loading: loadingManually }] = useMutation(AuthManually, {
    onCompleted: (data) => {
      if (data?.authToken?.token) {
        secureLocalStorage.setItem("token", data?.authToken?.token);
        secureLocalStorage.setItem(
          "user",
          JSON.stringify(data?.authToken?.user)
        );
        fetchDashboard({
          context: {
            headers: {
              Authorization: `Bearer ${data?.authToken?.token}`,
            },
          },
        });
      }
    },

    onError: () => {
      alert.error("Login Failed, please try again");
    },
  });
  const responseMessage = (response) => {
    authGoogle({ variables: { token: response.credential } });
  };
  const errorMessage = (error) => {
    alert.error("Login Failed, please try again");
  };

  const onChangeText = (e) => {
    const validate = formValidation(e.target.name, e.target.value);
    setError({
      ...error,
      [e.target.name]: !validate,
    });
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onHandleSubmit = () => {
    const newData = { ...data };
    let errors = 0;
    for (let i in newData) {
      const name = i;
      const value = newData[i];
      const validate = formValidation(name, value);

      if (!validate) {
        errors = errors + 1;
      }
      setError((prevState) => ({
        ...prevState,
        [name]: !validate,
      }));
    }

    if (errors > 0) {
      alert.error("Please fill all fields");
      return;
    }

    AuthManual({
      variables: {
        username: data.username,
        password: data.password,
      },
    });
  };

  return (
    <div style={{ height: "90vh" }}>
      {loading || loadingManually ? <CLoading /> : null}
      {loadingDashboard ? <CLoading /> : null}
      <Row>
        <Col sm="12" md="8" lg="8" className="d-none d-lg-block">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "90vh" }}
          >
            <img
              src={BannerLogin}
              alt="login"
              style={{
                height: "80vh",
              }}
            />
          </div>
        </Col>
        <Col
          sm="12"
          md="12"
          lg="4"
          className={`px-4 ${state.darkMode ? "bg-dark" : "bg-white"}`}
        >
          <div
            ref={divRef}
            className={`${
              state.darkMode ? "bg-dark" : "bg-white"
            }  d-flex pt-5 justify-content-center`}
            style={{ height: "90vh" }}
          >
            <div>
              <div className="d-flex flex-column mb-3">
                <span className="font-extra-large fw-semibold">
                  {t("login.title")}
                </span>
                <span className="text-secondary font-title">
                  {t("login.subtitle")}
                </span>
              </div>

              <div>
                <div>
                  <label for="email" className="fw-semibold font-label mb-2">
                    Email
                  </label>
                  <CInput
                    name="username"
                    type={"email"}
                    heightInput={"50px"}
                    borderColor={error?.username ? "border-danger" : ""}
                    value={data?.username}
                    invalid={error?.username}
                    placeholder={t("login.enter_email")}
                    onChange={onChangeText}
                  />
                  {error?.username && (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {t("forgot_password.valid_email")}
                    </span>
                  )}
                </div>
                <div className="mt-3">
                  <label for="email" className="fw-semibold font-label mb-2">
                    {t("login.password")}
                  </label>
                  <CInput
                    name="password"
                    type={showPassword ? "text" : "password"}
                    heightInput={"50px"}
                    borderColor={error?.password ? "border-danger" : ""}
                    value={data?.password}
                    invalid={error?.password}
                    placeholder={t("login.enter_password")}
                    onChange={onChangeText}
                    rightIcon={
                      !showPassword ? (
                        <FaRegEyeSlash width={20} height={20} />
                      ) : (
                        <FaRegEye />
                      )
                    }
                    rightAction={() => setShowPassword(!showPassword)}
                  />
                  {error?.password && (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {t("forgot_password.valid_email")}
                    </span>
                  )}
                </div>
                <div className="mt-3">
                  <Button
                    className="btn-primary shadow-none w-100 p-2 "
                    size="sm"
                    onClick={onHandleSubmit}
                  >
                    <span className="fw-bold font-label">
                      <FaUser /> Login
                    </span>
                  </Button>

                  <div
                    className="d-flex align-items-center"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/forgot-password")}
                  >
                    <span className="text-primary mt-2">
                      {t("login.forgot_password")}
                    </span>
                  </div>
                </div>
                <div className="py-3 text-center">
                  <span className="text-secondary font-description">
                    {t("login.or_using_email")}
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  <GoogleLogin
                    width={width}
                    onSuccess={responseMessage}
                    onError={errorMessage}
                    size="large"
                    type="standard"
                  />
                </div>
                <div className="mt-3 text-center">
                  <span className="text-secondary">
                    {t("login.dont_have_account")}{" "}
                    <NavLink
                      to="/register"
                      className="text-primary text-decoration-none"
                    >
                      <span className="font-label ">
                        {t("login.register_free")}
                      </span>
                    </NavLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
