import { gql } from "@apollo/client";

const QueryAnalize = gql`
  query MyQuery {
    analyze {
      id
      name
      code
    }
  }
`;

export default QueryAnalize;
