const HideEmail = (email) => {
  const hideEmail = (email) => {
    const [name, domain] = email.split("@");
    const firstLetter = name[0];
    const hiddenDomain = "@" + domain;
    return firstLetter + "*******" + hiddenDomain;
  };

  return hideEmail(email);
};

export default HideEmail;
