const emailRegx =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function formValidation(name, value) {
  switch (name) {
    case "email":
      if (!value) {
        return false;
      } else {
        return value && value.match(emailRegx) ? true : false;
      }
    case "password":
      return value ? true : false;
    default:
      return value ? true : false;
  }
}
