import React, { useState } from "react";
import { BsCopy, BsSearch } from "react-icons/bs";
import { CiHome } from "react-icons/ci";
import {
  FaBell,
  FaBookOpen,
  FaChartBar,
  FaCrown,
  FaPowerOff,
  FaSearch,
  FaTimes,
} from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { HiMenuAlt2 } from "react-icons/hi";
import { IoIosSwitch } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
} from "reactstrap";
import { Redbel } from "../../../assets/img";
import CInput from "../../../components/CInput";

const NavbarMobile = ({
  token,
  user,
  darkMode,
  t,
  onChangeLanguage,
  search,
  setSearch,
  companies,
  getCompany,
  i18n,
  languages,
  onLogout,
  subMenu,
  scrollToSection,
  sectionActive,
  companyState,
  settings,
}) => {
  const pathName = useLocation().pathname;
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [isShowUserModal, setIsShowUserModal] = useState(false);
  const [isShowBottomModal, setIsShowBottomModal] = useState(false);
  const [errorLogo, setErrorLogo] = useState(false);

  return (
    <div>
      <Navbar
        expand="md"
        color="white"
        className={`d-lg-none d-sm-block ${darkMode ? "bg-dark" : "bg-white"}`}
      >
        <NavbarBrand href="/">
          <img
            src={errorLogo ? Redbel : settings?.logo}
            onError={(e) => setErrorLogo(true)}
            alt="logo"
            style={{ maxWidth: "140px", height: "40px" }}
          />
        </NavbarBrand>
        <Nav className="ms-auto d-flex flex-row gap-3" navbar>
          <NavItem className=" d-flex align-items-center">
            <NavLink>
              <div
                className="language-switch-container"
                onClick={onChangeLanguage}
                style={{
                  cursor: "pointer",
                }}
              >
                <span
                  className={`language-label ${
                    i18n?.language === "en" ? "active" : ""
                  }`}
                >
                  EN
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={i18n?.language === "id"}
                    onChange={onChangeLanguage}
                  />
                  <span className="slider round"></span>
                </label>
                <span
                  className={`language-label ${
                    i18n?.language === "id" ? "active" : ""
                  }`}
                >
                  ID
                </span>
              </div>
            </NavLink>
          </NavItem>
          {token ? (
            <NavItem>
              <NavLink
                className="bottom-link"
                onClick={() => setIsShowUserModal(!isShowUserModal)}
              >
                <i
                  className={`fw-bold ${
                    darkMode ? "text-white" : "text-dark"
                  } font-label`}
                >
                  <FiUser size={16} />
                </i>
                <span
                  className={`fw-bold ${
                    darkMode ? "text-white" : "text-dark"
                  } font-label`}
                >
                  {t("navbar_user.account")}
                </span>
              </NavLink>
            </NavItem>
          ) : (
            <NavItem>
              <NavLink
                tag={Link}
                to="/login"
                className={
                  pathName === "/login" ? "bottom-link-active" : "bottom-link"
                }
              >
                <i
                  className={`fw-bold ${
                    darkMode ? "text-white" : "text-dark"
                  } font-label`}
                >
                  <FiUser size={16} />
                </i>
                <span
                  className={`fw-bold ${
                    darkMode ? "text-white" : "text-dark"
                  } font-label`}
                >
                  {t("navbar.LOGIN")}
                </span>
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Navbar>
      {pathName.includes("company") && (
        <Navbar
          style={{ width: "100%", overflowX: "auto", whiteSpace: "nowrap" }}
          expand="sm"
          className={`shadow-sm py-0 ${
            darkMode ? "bg-dark" : "bg-brown"
          } d-lg-none d-sm-block`}
        >
          <div className="d-flex flex-row py-2  gap-3">
            {subMenu.map((item, index) => (
              <div
                onClick={() => {
                  scrollToSection(item?.slug);
                }}
                key={index}
                className={
                  item?.slug === sectionActive &&
                  `border-bottom border-primary border-2 `
                }
              >
                <NavLink
                  className={`text-decoration-none  font-label ${
                    item?.slug === sectionActive
                      ? "text-primary fw-bold"
                      : "text-secondary"
                  } `}
                >
                  {!item?.label ? companyState?.name : item?.label}
                </NavLink>
              </div>
            ))}
          </div>
        </Navbar>
      )}
      {isShowSearch && (
        <Nav className="me-auto" navbar>
          <NavItem className="px-2 py-1">
            <CInput
              style={{ fontSize: "14px" }}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                getCompany({
                  variables: {
                    filter: e.target.value,
                  },
                });
              }}
              placeholder={t("home.search_company")}
              heightInput={"38px"}
              borderColor={"border-primary"}
              leftIcon={<FaSearch width={20} height={20} />}
              rightIcon={
                search?.length > 0 && (
                  <IoClose color="#0e3dea" width={20} height={20} />
                )
              }
              rightAction={() => setSearch("")}
            />
            {companies?.length > 0 && search?.length > 0 && (
              <div
                className={`position-absolute p-2 ${
                  darkMode ? "bg-secondary text-light" : "bg-light"
                } mt-1 rounded shadow-sm z-1`}
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {companies?.map((item) => (
                  <div
                    className="p-2 card-search"
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSearch("");
                      window.location.href = `/company/${item?.code}`;
                    }}
                  >
                    {item?.name}
                  </div>
                ))}
              </div>
            )}
          </NavItem>
        </Nav>
      )}
      <Nav
        className={`bottom-nav d-lg-none  ${darkMode ? "bg-dark" : "bg-white"}`}
      >
        <NavItem>
          {token ? (
            <NavLink
              tag={Link}
              to="/"
              className={
                pathName === "/" || pathName === "/dashboard"
                  ? "bottom-link-active"
                  : "bottom-link"
              }
            >
              <i
                className={`fw-bold ${
                  darkMode
                    ? "text-white"
                    : pathName === "/"
                    ? "fw-semibold text-primary"
                    : "text-dark"
                } font-label`}
              >
                <HiMenuAlt2 size={20} />
              </i>
              <span
                className={`fw-bold ${
                  darkMode
                    ? "text-white"
                    : pathName === "/"
                    ? "fw-semibold text-primary"
                    : "text-dark"
                } font-label`}
              >
                {t("navbar.FEED")}
              </span>
            </NavLink>
          ) : (
            <NavLink
              tag={Link}
              to="/"
              className={
                pathName === "/" ? "bottom-link-active" : "bottom-link"
              }
            >
              <i
                className={`fw-bold ${
                  darkMode
                    ? "text-white"
                    : pathName === "/"
                    ? "fw-semibold text-primary"
                    : "text-dark"
                } font-label`}
              >
                <CiHome size={20} />
              </i>

              <span
                className={`fw-bold ${
                  darkMode
                    ? "text-white"
                    : pathName === "/"
                    ? "fw-semibold text-primary"
                    : "text-dark"
                } font-label`}
              >
                {t("navbar.HOME")}
              </span>
            </NavLink>
          )}
        </NavItem>
        <NavItem>
          <NavLink
            tag={Link}
            to="/market"
            className={
              pathName === "/market" ? "bottom-link-active" : "bottom-link"
            }
          >
            <i
              className={`fw-bold ${
                darkMode
                  ? "text-white"
                  : pathName === "/market"
                  ? "fw-semibold text-primary"
                  : "text-dark"
              } font-label`}
            >
              <FaChartBar size={16} />
            </i>
            <span
              className={`fw-bold ${
                darkMode
                  ? "text-white"
                  : pathName === "/market"
                  ? "fw-semibold text-primary"
                  : "text-dark"
              } font-label`}
            >
              {" "}
              {t("navbar.MARKETS")}
            </span>
          </NavLink>
        </NavItem>

        {(pathName !== "/" && !token) || (pathName && token) ? (
          <NavItem>
            <NavLink className={"bottom-link"}>
              <Button
                color="primary"
                className="rounded-pill"
                onClick={() => setIsShowSearch(!isShowSearch)}
              >
                {isShowSearch ? <FaTimes size={15} /> : <FaSearch size={15} />}
              </Button>
            </NavLink>
          </NavItem>
        ) : null}
        <NavItem>
          <NavLink
            tag={Link}
            to="/screen"
            className={
              pathName === "/screen" ? "bottom-link-active" : "bottom-link"
            }
          >
            <i
              className={`fw-bold ${
                darkMode
                  ? "text-white"
                  : pathName === "/screen"
                  ? "fw-semibold text-primary"
                  : "text-dark"
              } font-label`}
            >
              <BsCopy size={16} />
            </i>
            <span
              className={`fw-bold ${
                darkMode
                  ? "text-white"
                  : pathName === "/screen"
                  ? "fw-semibold text-primary"
                  : "text-dark"
              } font-label`}
            >
              {" "}
              {t("navbar.SCREENS")}
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={Link}
            to="/news"
            className={
              pathName === "/news" ? "bottom-link-active" : "bottom-link"
            }
          >
            <i
              className={`fw-bold ${
                darkMode
                  ? "text-white"
                  : pathName === "/news"
                  ? "fw-semibold text-primary"
                  : "text-dark"
              } font-label`}
            >
              <BsCopy size={16} />
            </i>
            <span
              className={`fw-bold ${
                darkMode
                  ? "text-white"
                  : pathName === "/news"
                  ? "fw-semibold text-primary"
                  : "text-dark"
              } font-label`}
            >
              {" "}
              {t("navbar.NEWS")}
            </span>
          </NavLink>
        </NavItem>

        {/* <NavItem>
          <NavLink
            onClick={() => setIsShowBottomModal(!isShowBottomModal)}
            className="bottom-link"
          >
            <i
              className={`fw-bold ${
                darkMode ? "text-white" : "text-dark"
              } font-label`}
            >
              <GoTools size={16} />
            </i>
            <span
              className={`fw-bold ${
                darkMode ? "text-white" : "text-dark"
              } font-label`}
            >
              {t("navbar.TOOLS")}
            </span>
          </NavLink>
        </NavItem> */}
      </Nav>
      {/* Bottom Modal Tools */}
      <Modal
        isOpen={isShowBottomModal}
        toggle={() => setIsShowBottomModal(!isShowBottomModal)}
        style={{
          maxWidth: "100%",
          bottom: "0",
          position: "fixed",
          margin: "0",
          width: "100%",
          height: "auto",
        }}
        className="bottom-modal"
      >
        <ModalHeader
          className={`${
            darkMode ? "bg-dark text-white" : "bg-white text-primary"
          }`}
          toggle={() => setIsShowBottomModal(!isShowBottomModal)}
        ></ModalHeader>
        <ModalBody className={`${darkMode ? "bg-dark" : "bg-white"}`}>
          <div
            className={`m-1 bg-white p-3 shadow-sm  ${
              darkMode ? "bg-dark" : "bg-white"
            }`}
          >
            <div className="d-flex flex-row gap-2 mb-3">
              <div>
                <div
                  className={`dropdown-item-tools ${
                    darkMode ? "bg-secondary text-white" : "bg-white"
                  } `}
                >
                  <BsSearch size={25} />
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center">
                <p
                  className={`h6 ${
                    darkMode ? "text-white" : "text-secondary"
                  } mb-0`}
                >
                  Search a stock screen
                </p>
                <span
                  style={{
                    fontSize: "13px",
                    color: "#606F7B",
                  }}
                >
                  Run queries on 10 years financial data
                </span>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* Modal User */}
      <Modal
        isOpen={isShowUserModal}
        toggle={() => setIsShowUserModal(!isShowUserModal)}
        style={{
          maxWidth: "100%",
          bottom: "0",
          position: "fixed",
          margin: "0",
          width: "100%",
          height: "auto",
        }}
        className="bottom-modal"
      >
        <ModalHeader
          toggle={() => setIsShowUserModal(!isShowUserModal)}
        ></ModalHeader>
        <ModalBody className="bg-light">
          <div className="m-1 bg-white p-3 shadow-sm">
            <div className="d-flex flex-row gap-2">
              <div>
                <IoIosSwitch size={14} color="#4651F3" />
              </div>
              <div>
                <span style={{ fontSize: 14, fontWeight: "500" }}>
                  {t("navbar_user.profile")}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row gap-2 mt-2">
              <div>
                <FaBell size={14} color="#4651F3" />
              </div>
              <div>
                <span style={{ fontSize: 14, fontWeight: "500" }}>
                  {t("navbar_user.alerts")}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row gap-2  mt-2">
              <div>
                <FaBookOpen size={14} color="#4651F3" />
              </div>
              <div>
                <span style={{ fontSize: 14, fontWeight: "500" }}>
                  {t("navbar_user.notebook")}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row gap-2  mt-2">
              <div>
                <FaCrown size={14} color="#4651F3" />
              </div>
              <div>
                <span style={{ fontSize: 14, fontWeight: "500" }}>
                  {t("navbar_user.upgrade")}
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-row gap-2  pt-1 mt-2 border-top cursor-pointer"
              onClick={onLogout}
            >
              <div>
                <FaPowerOff size={14} color="#4651F3" />
              </div>
              <div>
                <span style={{ fontSize: 14, fontWeight: "500" }}>
                  {t("navbar_user.Logout")}
                </span>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NavbarMobile;
