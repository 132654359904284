import React, { useContext } from "react";
import { Modal } from "reactstrap";
import { GlobalContext } from "../contexts/GlobalContext";

const CModalSide = ({ isOpen, setIsOpen, children }) => {
  const { state } = useContext(GlobalContext);
  return (
    <Modal
      isOpen={isOpen}
      toggle={setIsOpen}
      style={{
        position: "fixed",
        margin: 0,
        right: 0,
        height: "100%",
        maxWidth: "400px",
      }}
      contentClassName={`${
        state?.darkMode ? "dark-mode" : ""
      } custom-modal-content`}
    >
      {children}
    </Modal>
  );
};

export default CModalSide;
