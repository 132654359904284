import { gql } from "@apollo/client";

const UpdateProfile = gql`
  mutation UpdateProfile(
    $dob: Date
    $gender: GenderEnum
    $dashboard: DashboardInputSchema
  ) {
    updateProfile(
      dob: $dob
      gender: $gender
      dashboard: $dashboard
      screen: "{}"
    ) {
      dob
      imageUrl
      gender
      screen
      dashboard {
        section
        sectionDisplayName
        value
        valueDisplayName
        unit
        origin {
          key
          value
        }
      }
    }
  }
`;

export default UpdateProfile;
