import React from "react";

const Detail = () => {
  return (
    <div>
      <p>Detail</p>
    </div>
  );
};

export default Detail;
