import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { IoIosArrowDropright } from "react-icons/io";
import { Card, CardBody } from "reactstrap";
import { NoData } from "../../../assets/img";
import CEmpty from "../../../components/CEmpty";
import { isCurrencyNumber } from "../../../utils/isCurrency";

const ChartMarket = ({ data, name = "", title = "", value }) => {
  const { t } = useTranslation("global");
  const [series, setSeries] = useState([
    {
      name: "Day change %",
      data: [],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
  });

  const onModifyChart = (data) => {
    setSeries([
      {
        name: name,
        data: data
          .sort(
            (a, b) => b?.stockSummaries[0][value] - a?.stockSummaries[0][value]
          )
          .map((item) => item?.stockSummaries[0][value]),
      },
    ]);

    setOptions({
      chart: {
        height: 250,
        type: "bar",
        padding: 0,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: "10%",
        },
      },

      xaxis: {
        categories: data.map((item) => item.code),
        labels: {
          show: true,
          formatter: function (value, index) {
            // Tampilkan hanya label pada interval tertentu
            return index % 2 === 0 ? value : "";
          },
        },
      },
      yaxis: {
        show: true,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },

      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          const stock = data[opts?.dataPointIndex].stockSummaries[0];
          if (value === "volume1weekAverage") {
            return parseFloat(stock[value]).toFixed(2);
          } else {
            return stock[value];
          }
        },
        offsetY: 0,
        style: {
          fontSize: "12px",
          colors: ["#FFF"],
        },
      },

      colors: data.map((item) => {
        const stock = item?.stockSummaries[0];
        return stock[value] < 0 ? "#ff0000" : "#0078ff";
      }),

      tooltip: {
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const stock = data[dataPointIndex].stockSummaries[0];
          return `
          <div style="background-color: #ffebcd; border: 1px solid #f9f871; padding: 10px; border-radius: 5px; font-size: 10px; color: #000;">
            <span style="font-weight: bold;">${
              data[dataPointIndex].name
            }</span><br>
            <span className="font-description">Current Price: ${isCurrencyNumber(
              stock.price
            )}</span><br>
            <span className="font-description">Day Change : ${
              stock.change
            }%</span><br>
            <span className="font-description">Day Volume: ${
              stock.volume
            }</span><br>
            <span className="font-description">Week Volume Avg: ${
              stock.volume1weekAverage
            }</s>
          </div>
        `;
        },
      },
    });
  };

  useEffect(() => {
    onModifyChart(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <Card>
      <div className="d-flex justify-content-between flex-row mt-3">
        <div className="d-flex justify-content-start">
          <div className="bg-brown p-1 ">
            <span className="text-dark fw-bold font-description">{title}</span>
          </div>
        </div>
        <div className="d-flex flex-row gap-1 align-items-center me-1">
          <span className="font-small text-primary">VIEW ALL</span>
          <IoIosArrowDropright size={16} className="text-primary" />
        </div>
      </div>
      <CardBody>
        {data?.length > 0 ? (
          <ApexCharts
            options={options}
            series={series}
            type="bar"
            height={180}
          />
        ) : (
          <CEmpty
            className="d-flex justify-content-center align-items-center flex-column"
            image={<img src={NoData} alt="No Data" width={300} />}
            title={t("detail.no_data")}
          />
        )}
        <div className="d-flex justify-content-center">
          <span className="font-label ">{name}</span>
        </div>
      </CardBody>
    </Card>
  );
};

export default ChartMarket;
