import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import { Redbel } from "../../assets/img";
import { GlobalContext } from "../../contexts/GlobalContext";

const Footer = () => {
  const { state, toggleTheme, settings } = useContext(GlobalContext);

  const [errorLogo, setErrorLogo] = useState(false);

  return (
    <footer className={`footer px-5 `}>
      <Container fluid>
        <Row className="footer-content align-items-center">
          <Col xs="12" md="6" className="footer-section">
            <img
              src={errorLogo ? Redbel : settings?.logo}
              onError={(e) => setErrorLogo(true)}
              alt="logo"
              style={{ maxWidth: "140px", height: "40px" }}
            />
            <h5>{settings?.descriptions}</h5>
          </Col>

          <Col xs="12" md="6" className="footer-section">
            <Row>
              {/* Product */}
              <Col xs="12" sm="4" className="footer-column">
                <Nav vertical>
                  <h6 className="footer-title">Team</h6>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/about-us"
                      className="font-description"
                    >
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/terms-and-conditions"
                      className="font-description "
                    >
                      Terms & Conditions
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/privacy-policy"
                      className="font-description"
                    >
                      Privacy Policy
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>

              {/* Team */}
              {/* <Col xs="12" sm="4" className="footer-column">
                <h5 className="footer-title">Team</h5>
                <Nav vertical>
                  <NavItem>
                    <NavLink href="#">Team 1</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">Team 2</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">Team 3</NavLink>
                  </NavItem>
                </Nav>
              </Col> */}

              {/* Theme */}
              <Col xs="12" sm="4" className="footer-column ">
                <div className="language-switch-container">
                  <span
                    className={`language-label ${
                      !state?.darkMode ? "active" : ""
                    }`}
                  >
                    Light
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={state?.darkMode === true}
                      onChange={toggleTheme}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span
                    className={`language-label ${
                      state?.darkMode ? "active" : ""
                    }`}
                  >
                    Dark
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
