import { gql } from "@apollo/client";

const VerifyRegistration = gql`
  mutation MyMutation($code: String!) {
    verifyRegistration(code: $code) {
      message
      status
      error
    }
  }
`;

export default VerifyRegistration;
