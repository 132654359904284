import React, { useContext, useEffect, useState } from "react";
import { HiMenuAlt2 } from "react-icons/hi";
import { Badge, Button, Card, CardBody, Col, Row } from "reactstrap";
import MetaHelmet from "../../components/MetaHelmet";

import { useLazyQuery, useMutation } from "@apollo/client";
import { AiOutlineLineChart } from "react-icons/ai";
import { GrAnnounce } from "react-icons/gr";
import { IoIosArrowForward } from "react-icons/io";
import { IoCopyOutline } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { TbGridDots } from "react-icons/tb";
import { TfiMenuAlt } from "react-icons/tfi";
import secureLocalStorage from "react-secure-storage";
import CLoading from "../../components/CLoading";
import { GlobalContext } from "../../contexts/GlobalContext";
import UpdateProfile from "../../graphQL/Mutation/UpdateProfile";
import QueryProfile from "../../graphQL/Query/QueryProfile";
import IsMobile from "../../utils/IsMobile";

const Dashboard = () => {
  const mobileView = IsMobile();
  const { state } = useContext(GlobalContext);
  const [dashboard_obj] = useState(
    secureLocalStorage.getItem("dashboard_obj")
      ? JSON.parse(secureLocalStorage.getItem("dashboard_obj"))
      : null
  );

  const [dashboard] = useState({
    ratio: {
      marketCapitalization: true,
      bookValue: true,
      returnOnEquity: true,
      dividendYield: true,
    },
    annualIncome: {
      returnOnCapitalEmployed: true,
    },
    price: {
      currentPrice: true,
      highPrice: true,
      lowPrice: true,
    },
    balanceSheet: {
      faceValue: true,
    },
  });

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const [fetchDashboard, { loadingDashboard }] = useLazyQuery(QueryProfile, {
    context: {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },
    onCompleted: (data) => {
      const newDashboard = data?.profile?.userProfiles?.dashboard.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.section]: {
              ...acc[curr.section],
              [curr.value]: true,
            },
          };
        },
        {}
      );
      if (!isEmptyObject(newDashboard)) {
        secureLocalStorage.setItem(
          "dashboard_obj",
          JSON.stringify(newDashboard)
        );
        secureLocalStorage.setItem(
          "dashboard_arr",
          JSON.stringify(data?.profile?.userProfiles?.dashboard)
        );
      }
    },
  });

  const [updateRatio, { loading }] = useMutation(UpdateProfile, {
    context: {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },

    onCompleted: () => {
      fetchDashboard();
    },
    onError: () => {
      console.log("Error update ratio first login");
    },
  });

  useEffect(() => {
    updateRatio({
      variables: {
        dashboard: dashboard_obj ? dashboard_obj : dashboard,
      },
    });
    //  eslint-disable-next-line
  }, []);
  return (
    <div>
      <MetaHelmet title="Dashboard" />
      {loading && loadingDashboard ? <CLoading /> : null}
      <Row>
        <Col md="8" lg="8" sm="12">
          <div className="d-flex flex-row align-items-center border-bottom mx-lg-3 pb-1 justify-content-between">
            <Col md="6" lg="6" sm="3">
              <div>
                <div className="d-flex flex-row gap-2 align-items-center">
                  <HiMenuAlt2 color="#665FFD" width={25} height={25} />
                  <div>
                    <span
                      style={{
                        fontSize: mobileView ? 14 : 20,
                        fontWeight: "500",
                      }}
                    >
                      Core Watchlist
                    </span>
                  </div>
                  <MdOutlineKeyboardArrowDown width={25} height={25} />
                </div>
              </div>
            </Col>
            <Col
              md="6"
              lg="6"
              sm="6"
              className="d-flex justify-content-end gap-lg-2 gap-sm-1"
            >
              <div
                className={`${
                  state.darkMode ? "bg-dark" : "bg-light"
                } border rounded d-flex flex-row align-items-center gap-2 px-2 py-lg-1`}
              >
                <TbGridDots width={25} height={25} />
                <div>
                  <span
                    className="text-secondary"
                    style={{
                      fontSize: mobileView ? 10 : 14,
                      fontWeight: "bold",
                    }}
                  >
                    WATCHLIST VIEW
                  </span>
                </div>
              </div>
              <div
                className={`${
                  state.darkMode ? "bg-dark" : "bg-light"
                } py-1 px-3 bg-white border rounded`}
              >
                <TfiMenuAlt width={20} height={20} />
              </div>
            </Col>
          </div>
          <Card className="mt-lg-4 shadow-sm">
            <CardBody>
              <div className="d-flex  flex-column justify-content-center align-items-center py-2">
                <IoCopyOutline size={20} />
                <div className="mt-2">
                  <p
                    className="fw-bolder "
                    style={{ fontSize: mobileView ? 16 : 20 }}
                  >
                    Add companies to watchlist
                  </p>
                </div>
                <div
                  className={"text-center text-secondary"}
                  style={{
                    width: mobileView ? "100%" : "50%",
                  }}
                >
                  <p style={{ fontSize: mobileView ? 14 : 16 }}>
                    Keep track of latest announcements, insider trades and
                    credit ratings of your companies.
                  </p>
                </div>
                <div>
                  <Button color="primary">
                    <span className="fw-bolder">ADD COMPANIES</span>
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="4" lg="4" sm="12">
          <Card className="mt-lg-4 shadow-sm ms-lg-3 me-lg-5">
            <CardBody>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2 border-bottom pb-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <GrAnnounce size={14} />
                  <span
                    style={{
                      fontSize: mobileView ? 12 : 14,
                      fontWeight: "500",
                    }}
                  >
                    Latest Fillings
                  </span>
                </div>
                <IoIosArrowForward size={14} />
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2 border-bottom py-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <AiOutlineLineChart size={14} />
                  <span
                    style={{
                      fontSize: mobileView ? 12 : 14,
                      fontWeight: "500",
                    }}
                  >
                    Quarterly Results
                  </span>
                </div>
                <IoIosArrowForward size={14} />
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2  py-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <SlCalender size={14} />
                  <span
                    style={{
                      fontSize: mobileView ? 12 : 14,
                      fontWeight: "500",
                    }}
                  >
                    Upcoming Results
                  </span>
                </div>
                <IoIosArrowForward size={14} />
              </div>
            </CardBody>
          </Card>
          <Card className="mt-lg-4 shadow-sm ms-lg-3 me-lg-5 d-lg-block d-none">
            <CardBody className="m-0 p-0">
              <div
                className="rounded"
                style={{
                  height: mobileView ? 200 : 300,
                }}
              >
                <img
                  src={
                    "https://cdn-media.screener.in/capsules/stock-alerts.png"
                  }
                  alt="login"
                  width="100%"
                  height="100%"
                />
              </div>
              <div
                style={{
                  position: "relative ",
                  top: "-15px",
                  left: "15px",
                }}
              >
                <Badge color="success" className="px-2 py-1">
                  <span
                    style={{
                      fontSize: "13px",
                      fontWeight: "normal",
                      color: "#FFF",
                    }}
                  >
                    🔥 Power Feature
                  </span>
                </Badge>
              </div>

              <div
                className="px-3 d-flex flex-column pb-3"
                style={{ marginTop: "-10px" }}
              >
                <span
                  style={{ fontSize: mobileView ? 14 : 16, fontWeight: "500" }}
                >
                  Stock Alerts
                </span>
                <span
                  className="text-secondary"
                  style={{ fontSize: mobileView ? 12 : 14, fontWeight: "500" }}
                >
                  Get notified if the price reaches your buy zone. Even after
                  years. Set and forget.
                </span>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
