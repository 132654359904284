import React from "react";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaInfoCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";

const CAlert = ({ style, options, message, close }) => {
  let icon;

  switch (options.type) {
    case "success":
      icon = <FaCheckCircle style={{ color: "white", marginRight: "10px" }} />;
      break;
    case "error":
      icon = <FaTimesCircle style={{ color: "white", marginRight: "10px" }} />;
      break;
    case "info":
      icon = <FaInfoCircle style={{ color: "white", marginRight: "10px" }} />;
      break;
    case "warning":
      icon = (
        <FaExclamationCircle style={{ color: "white", marginRight: "10px" }} />
      );
      break;
    default:
      icon = null;
  }
  const getColor = (options) => {
    switch (options.type) {
      case "success":
        return "#117f8c";

      case "error":
        return "#ff0000";

      case "info":
        return "#117f8c";

      case "warning":
        return "#ffcc33";

      default:
        return null;
    }
  };

  return (
    <div
      className="rounded-3 px-3 py-2 text-white d-flex flex-row align-items-center"
      style={{
        ...style,
        marginTop: "70px",
        backgroundColor: getColor(options),
        color: "black",

        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
        display: "flex",
      }}
    >
      {icon}
      <span style={{ fontSize: "16px", color: "white" }}>{message}</span>
      <button
        onClick={close}
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "black",
          fontSize: "18px",
          cursor: "pointer",
        }}
      >
        <MdOutlineClose color="white" />
      </button>
    </div>
  );
};

export default CAlert;
