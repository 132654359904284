import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const CBreadcrumb = ({ items, currentPath }) => {
  return (
    <div>
      <Breadcrumb>
        {items.map((item, index) => (
          <BreadcrumbItem
            key={index}
            active={item.path === currentPath}
            className="font-label text-decoration-none "
          >
            {item.path === currentPath ? (
              item.label
            ) : (
              <a
                href={item.path}
                className="font-label text-decoration-none text-primary"
              >
                {item.label}
              </a>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default CBreadcrumb;
