import { gql } from "@apollo/client";

const QueryRank = gql`
  query Rank($type: TYPE, $limit: Int) {
    rank(rankType: $type, limit: $limit) {
      name
      code
      stockSummaries {
        change
        price
        volume
        stockRank
        volume1weekAverage
        tradeFrequency
      }
    }
  }
`;

export default QueryRank;
