import moment from "moment";
import React, { useContext, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Redbel } from "../../assets/img";
import { GlobalContext } from "../../contexts/GlobalContext";

const Term = () => {
  const { settings } = useContext(GlobalContext);
  const [errorLogo, setErrorLogo] = useState(false);
  return (
    <div>
      <Card>
        <CardBody>
          <div className="text-center ">
            <p className="font-extra-large fw-semibold  my-2">
              Terms and Conditions
            </p>
            <img
              alt="logo"
              src={errorLogo ? Redbel : settings?.logo}
              height={"150px"}
              width={"300px"}
              onError={(e) => setErrorLogo(true)}
            />
          </div>
          <div>
            <p className="font-label">
              Last updated:{" "}
              <span className="fw-bold">
                {moment(settings?.termsUpdated).format("DD MMMM YYYY")}
              </span>
            </p>
          </div>
          <div dangerouslySetInnerHTML={{ __html: settings?.terms }} />
        </CardBody>
      </Card>
    </div>
  );
};

export default Term;
