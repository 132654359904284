import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import RequestResetPassword from "../../../graphQL/Mutation/RequestResetPassword";
import formValidation from "../../../utils/formValidation";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { t } = useTranslation("global");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);

  const onChangeMail = (e) => {
    const validate = formValidation("email", e.target.value);
    setErrorEmail(!validate);
    setEmail(e.target.value);
  };

  const [reqResetPassword, { loading }] = useMutation(RequestResetPassword, {
    onCompleted: (data) => {
      if (data?.requestResetPassword?.status) {
        navigate("/forgot-password/done", { state: { email: email } });
      }
    },
    onError: (error) => {
      alert.error("Failed to reset password, please try again");
    },
  });

  const onHandleSubmit = (e) => {
    const validate = formValidation("email", email);
    setErrorEmail(!validate);
    if (!validate) {
      alert.error(t("forgot_password.valid_email"));
      return false;
    }
    reqResetPassword({
      variables: {
        email: email,
      },
    });
  };
  return (
    <div className="d-flex justify-content-center ">
      <Card style={{ width: "450px" }}>
        <CardHeader className="bg-white border-0">
          <CardTitle>
            <div
              className="d-flex flex-row gap-3"
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            >
              <div>
                <FaArrowLeft size={20} />
              </div>
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {t("forgot_password.reset_password")}
                </span>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            <p
              className="text-secondary"
              style={{ fontSize: "14px", lineHeight: "16px" }}
            >
              {t("forgot_password.description")}
            </p>
          </div>
          <FormGroup className="mt-4">
            <Input
              id="email"
              value={email}
              className={
                errorEmail ? "shadow-none border-danger" : "shadow-none"
              }
              name="email"
              placeholder={t("login.enter_email")}
              onChange={onChangeMail}
              type="email"
            />
            {errorEmail && (
              <div
                className="text-danger"
                style={{
                  fontSize: "12px",
                }}
              >
                {t("forgot_password.valid_email")}
              </div>
            )}
          </FormGroup>
          <Button color="success" className="w-100" onClick={onHandleSubmit}>
            {loading ? <Spinner size="sm" /> : t("forgot_password.next")}
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default ForgotPassword;
