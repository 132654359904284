import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import CInput from "../../components/CInput";
import CLoading from "../../components/CLoading";
import MetaHelmet from "../../components/MetaHelmet";
import { GlobalContext } from "../../contexts/GlobalContext";
import GetCompany from "../../graphQL/Query/GetCompany";
import QueryAnalize from "../../graphQL/Query/QueryAnalize";
import IsMobile from "../../utils/IsMobile";

const Home = () => {
  const { state, settings } = useContext(GlobalContext);
  const [search, setSearch] = useState("");
  const { t } = useTranslation("global");
  const mobileView = IsMobile();

  const [analyse, setAnalyse] = useState([]);
  const [companies, setCompanies] = useState([]);

  const { refetch: refetchCompany } = useQuery(GetCompany, {
    variables: {
      filter: search,
    },
    onCompleted: (data) => {
      setCompanies(data?.company);
    },
  });
  const { loading, refetch: refetchAnalyse } = useQuery(QueryAnalize, {
    onCompleted(data) {
      setAnalyse(data?.analyze);
    },
  });

  useEffect(() => {
    refetchAnalyse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetchCompany();
    // eslint-disable-next-line
  }, [search]);

  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{
        height: "70vh",
      }}
    >
      <MetaHelmet />
      {loading && <CLoading />}
      <div className="text-center">
        <p
          className="mb-0"
          style={{
            fontSize: mobileView ? "28px" : "50px",
            fontWeight: "bold",
          }}
        >
          Redbel
        </p>
        <p
          className="mb-4"
          style={{
            fontSize: mobileView ? "16px" : "18px",
            fontWeight: "400",
          }}
        >
          {t("home.description")}
        </p>
      </div>
      <div>
        <CInput
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder={t("home.search_company")}
          widthInput={mobileView ? "100%" : "618px"}
          heightInput={mobileView ? "40px" : "60px"}
          borderColor={"border-primary"}
          leftIcon={<FaSearch size={20} />}
          rightIcon={
            search?.length > 0 && <IoClose color="#0e3dea" size={20} />
          }
          rightAction={() => setSearch("")}
        />
        {companies?.length > 0 && search?.length > 0 && (
          <div
            className={`position-absolute p-2 ${
              state?.darkMode ? "bg-secondary text-light" : "bg-light"
            } mt-1 rounded shadow-sm z-1`}
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              width: mobileView ? "250px" : "618px",
            }}
          >
            {companies?.map((item) => (
              <div
                className="p-2 card-search"
                style={{
                  fontSize: mobileView ? "10px" : "14px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  (window.location.href = `/company/${item?.code}`)
                }
              >
                {item?.name}
              </div>
            ))}
          </div>
        )}
      </div>

      <div
        className="text-left mt-3 d-flex flex-wrap justify-content-center align-items-center"
        style={{
          width: mobileView ? "100%" : "618px",
        }}
      >
        <div className="d-flex flex-row flex-wrap gap-2 justify-content-center align-items-center">
          <span style={{ fontSize: "16px", fontWeight: "500" }}>
            {t("home.or_analyze")}
          </span>
          {analyse?.map((item) => (
            <div
              className="border p-2 rounded card-search bg-primary text-light"
              style={{
                fontSize: mobileView ? "10px" : "14px",
                cursor: "pointer",
              }}
              onClick={() => (window.location.href = `/company/${item?.code}`)}
            >
              {item?.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
