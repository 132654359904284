import React from "react";
import CEmpty from "../../../components/CEmpty";

const SectionAnalys = ({ t, companiesDetail, darkMode }) => {
  return (
    <div className="d-flex flex-lg-row flex-column gap-3 mt-4">
      <div className="w-lg-50 w-100 border-top border-5 border-success rounded bg-light shadow-sm">
        <div className="p-3">
          <p className="text-title fw-bold">{t("detail.PROS")}</p>
          {companiesDetail?.pros ? (
            <div
              dangerouslySetInnerHTML={{
                __html: companiesDetail?.pros,
              }}
            />
          ) : (
            <CEmpty
              className="d-flex justify-content-center"
              title={t("detail.no_data")}
            />
          )}
        </div>
      </div>
      <div className="w-lg-50 w-100 border-top border-5 border-danger rounded bg-light shadow-sm">
        <div className="p-3">
          <p className="text-title fw-bold">{t("detail.CONS")}</p>

          {companiesDetail?.cons ? (
            <div
              dangerouslySetInnerHTML={{
                __html: companiesDetail?.cons,
              }}
            />
          ) : (
            <CEmpty
              className="d-flex justify-content-center"
              title={t("detail.no_data")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionAnalys;
