import { gql } from "@apollo/client";

const RequestResetPassword = gql`
  mutation MyMutation($email: String!) {
    requestResetPassword(email: $email) {
      message
      status
      error
    }
  }
`;

export default RequestResetPassword;
