import React from "react";
import { Card, CardBody, Col, Row, Table, Tooltip } from "reactstrap";
import { isCurrencyWithOutRp } from "../../../utils/isCurrency";
import IsMobile from "../../../utils/IsMobile";

const SectionPeer = ({
  companiesDetail,
  t,
  darkMode,
  fieldPeerComparison,
  tooltipOpenPeer,
  toggleTooltip,
  peerComparison,
}) => {
  const mobileView = IsMobile();
  return (
    <Card className="mt-4 shadow-sm">
      <CardBody>
        <Row className="d-flex flex-row justify-content-between align-items-center mb-2">
          <Col sm="12" md="6" lg="8" className="d-flex flex-column">
            <span className="font-extra-large fw-bold">
              {t("detail.peer_comparison")}
            </span>
            <div className="d-flex flex-row gap-5">
              <div>
                <span className="text-secondary font-label">
                  {t("detail.sector")}
                  <span className="text-primary font-label fw-bold">
                    : {companiesDetail?.primarySector}
                  </span>
                </span>
              </div>
              <div>
                <span className="text-secondary font-label">
                  {t("detail.industry")}
                  <span className="text-primary font-label fw-bold">
                    : {companiesDetail?.industry}
                  </span>
                </span>
              </div>
            </div>
          </Col>
        </Row>
        {fieldPeerComparison?.map((item) => (
          <Tooltip
            key={item.slug}
            placement="top"
            isOpen={tooltipOpenPeer[item.slug] || false}
            target={item.slug}
            toggle={() => toggleTooltip(item.slug)}
          >
            {t(`detail.${item.slug}`)}
          </Tooltip>
        ))}
        <div className="table-container">
          <Table className={`detail-table ${darkMode && "table-dark"}`}>
            <thead>
              <tr className="peer-tr">
                {fieldPeerComparison?.map((item, index) => (
                  <th
                    className={
                      index === 0
                        ? "peer-first-col"
                        : index === 1
                        ? "peer-second-col"
                        : ""
                    }
                    id={item?.slug}
                    key={index}
                    style={{
                      fontWeight: "500",
                      fontSize: mobileView ? "10px" : "14px",
                    }}
                  >
                    {item?.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {peerComparison?.map((item, index) => (
                <tr key={index} className="peer-tr">
                  <td
                    className="peer-first-col"
                    style={{
                      fontWeight: index === 0 ? "bold" : "normal",
                      fontSize: mobileView ? "10px" : "14px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    className="peer-second-col"
                    style={{
                      fontWeight: index === 0 ? "bold" : "normal",
                      fontSize: mobileView ? "10px" : "14px",
                    }}
                  >
                    {item?.name}
                  </td>
                  <td
                    style={{
                      fontWeight: index === 0 ? "bold" : "normal",
                      fontSize: mobileView ? "10px" : "14px",
                    }}
                  >
                    {isCurrencyWithOutRp(
                      item?.stockSummaries?.length > 0
                        ? item?.stockSummaries[0]?.price
                        : 0
                    )}
                  </td>
                  <td
                    style={{
                      fontWeight: index === 0 ? "bold" : "normal",
                      fontSize: mobileView ? "10px" : "14px",
                    }}
                  >
                    {isCurrencyWithOutRp(
                      item?.ratio?.length > 0
                        ? item?.ratio[0]?.priceToEarning
                        : 0
                    )}
                  </td>
                  <td
                    style={{
                      fontWeight: index === 0 ? "bold" : "normal",
                      fontSize: mobileView ? "10px" : "14px",
                    }}
                  >
                    {isCurrencyWithOutRp(
                      item?.ratio?.length > 0
                        ? item?.ratio[0]?.marketCapital
                        : 0
                    )}
                  </td>
                  <td
                    style={{
                      fontWeight: index === 0 ? "bold" : "normal",
                      fontSize: mobileView ? "10px" : "14px",
                    }}
                  >
                    {item?.ratio?.length > 0
                      ? item?.ratio[0]?.dividendYield
                      : 0}
                    %
                  </td>
                  <td
                    style={{
                      fontWeight: index === 0 ? "bold" : "normal",
                      fontSize: mobileView ? "10px" : "14px",
                    }}
                  >
                    {isCurrencyWithOutRp(
                      item?.quarterlyIncome?.length > 0
                        ? item?.quarterlyIncome[0]?.netProfitLatestQuarter
                        : 0
                    )}
                  </td>
                  <td
                    style={{
                      fontSize: mobileView ? "10px" : "14px",
                      fontWeight: index === 0 ? "bold" : "normal",
                    }}
                  >
                    {item?.quarterlyIncome?.length > 0
                      ? item?.quarterlyIncome[0]?.yoyQuarterlyProfitGrowth
                      : 0}
                    %
                  </td>
                  <td
                    style={{
                      fontSize: mobileView ? "10px" : "14px",
                      fontWeight: index === 0 ? "bold" : "normal",
                    }}
                  >
                    {isCurrencyWithOutRp(
                      item?.quarterlyIncome?.length > 0
                        ? item?.quarterlyIncome[0]?.salesLatestQuarter
                        : 0
                    )}
                  </td>
                  <td
                    style={{
                      fontSize: mobileView ? "10px" : "14px",
                      fontWeight: index === 0 ? "bold" : "normal",
                    }}
                  >
                    {item?.quarterlyIncome?.length > 0
                      ? item?.quarterlyIncome[0]?.yoyQuarterlySalesGrowth
                      : 0}
                    %
                  </td>
                  <td
                    style={{
                      fontSize: mobileView ? "10px" : "14px",
                      fontWeight: index === 0 ? "bold" : "normal",
                    }}
                  >
                    {item?.annualIncome?.length > 0
                      ? item?.annualIncome[0]?.returnOnCapitalEmployed
                      : 0}
                    %
                  </td>
                </tr>
              ))}
              <tr className="peer-tr">
                <td
                  className="peer-first-col text-secondary text-center"
                  style={{
                    fontSize: mobileView ? "10px" : "14px",
                  }}
                ></td>
                <td
                  className="peer-first-col text-secondary text-center"
                  style={{
                    fontSize: mobileView ? "10px" : "14px",
                    fontWeight: "500",
                  }}
                >
                  Median: {peerComparison?.length} Co.
                </td>
                <td
                  colSpan={9}
                  className="peer-first-col text-secondary text-center"
                  style={{
                    fontSize: mobileView ? "10px" : "14px",
                    fontWeight: "500",
                  }}
                >
                  Comming Soon
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default SectionPeer;
