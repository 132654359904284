import { gql } from "@apollo/client";

const ResendRegistrationEmail = gql`
  mutation MyMutation($email: String!) {
    resendRegistrationEmail(email: $email) {
      message
      status
      error
    }
  }
`;

export default ResendRegistrationEmail;
