import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { CiCircleRemove } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CInput from "../../components/CInput";
import CLoading from "../../components/CLoading";
import { GlobalContext } from "../../contexts/GlobalContext";
import UpdateProfile from "../../graphQL/Mutation/UpdateProfile";
import IsMobile from "../../utils/IsMobile";
import ratioJSOn from "./tabRatio.json";

const ManageRatio = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation("global");
  const mobileView = IsMobile();
  const alert = useAlert();
  const { parameters, state } = useContext(GlobalContext);
  const [tabRatio] = useState(ratioJSOn);
  const [tabSelected, setTabSelected] = useState(ratioJSOn[0]);
  const [parametersTemp, setParametersTemp] = useState(parameters);
  const [search, setSearch] = useState("");
  const [dashboard_arr, setDashboardArr] = useState(
    secureLocalStorage.getItem("dashboard_arr")
      ? JSON.parse(secureLocalStorage.getItem("dashboard_arr"))
      : null
  );
  const [dashboard_obj, setDashboardObj] = useState(
    secureLocalStorage.getItem("dashboard_obj")
      ? JSON.parse(secureLocalStorage.getItem("dashboard_obj"))
      : null
  );

  const onSearch = (value) => {
    setSearch(value);
    const newParameters = parameters.filter((parameter) =>
      parameter.valueDisplayName.toLowerCase().includes(value.toLowerCase())
    );
    setParametersTemp(newParameters);
  };

  const [updateRatio, { loading }] = useMutation(UpdateProfile, {
    context: {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },
    onCompleted: () => {
      secureLocalStorage.setItem(
        "dashboard_arr",
        JSON.stringify(dashboard_arr)
      );
      secureLocalStorage.setItem(
        "dashboard_obj",
        JSON.stringify(dashboard_obj)
      );
      alert.success("Update ratio successfully");
      setTimeout(() => {
        navigate("/company/" + companyId);
      }, 1000);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onCheckChecked = (item) => {
    if (dashboard_arr?.some((summary) => summary.value === item.value)) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeChecked = (e, item) => {
    if (onCheckChecked(item)) {
      setDashboardArr(
        dashboard_arr.filter((summary) => summary.value !== item.value)
      );
      let newDashboard = {
        ...dashboard_obj,
      };

      delete newDashboard[item.section][item.value];

      setDashboardObj(newDashboard);
    } else {
      if (dashboard_arr.length >= 15) {
        alert.info(t("screen.limit_reached"));
        return;
      }
      setDashboardArr([...dashboard_arr, item]);
      let newDashboard = {
        ...dashboard_obj,
      };
      newDashboard[item.section] = {
        ...newDashboard[item.section],
        [item.value]: true,
      };
      setDashboardObj(newDashboard);
    }
  };

  const onHandleSubmit = () => {
    updateRatio({
      variables: {
        dashboard: dashboard_obj,
      },
    });
  };

  return (
    <div>
      <Card>
        {loading && <CLoading />}
        <CardBody>
          <Row>
            <Col sm="12" lg="8" md="12">
              <div className="d-flex flex-row gap-2 flex-wrap">
                {dashboard_arr?.map((item) => (
                  <div
                    onClick={(e) => onChangeChecked(e, item)}
                    className="border rounded p-2 d-flex  align-items-center gap-2"
                    key={item.value}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <span className="font-label">{item.valueDisplayName}</span>
                    <CiCircleRemove size={14} />
                  </div>
                ))}
              </div>
            </Col>
            <Col sm="12" lg="4" md="12">
              <div className="d-flex justify-content-end gap-2">
                <Button color="primary" onClick={onHandleSubmit}>
                  {t("manage_ratio.save_ratios")}
                </Button>
                <Button color="danger" outline>
                  {t("manage_ratio.cancel")}
                </Button>
              </div>
            </Col>
          </Row>
          <Row
            className={`mt-sm-2 mt-2 mt-lg-3 py-2 ${
              state?.darkMode ? "bg-darkMode" : "bg-brown"
            } d-flex align-items-end`}
          >
            <Col sm="12" lg="3" md="12">
              <span className="font-label fw-bold mb-1">
                {t("manage_ratio.filter_ratio")}
              </span>
              <CInput
                placeholder={"Search ratio"}
                heightInput={"40px"}
                value={search}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
                leftIcon={<FaSearch size={14} />}
              />
            </Col>
            <Col sm="12" lg="9" md="12">
              <ButtonGroup
                className="border flex-wrap mt-sm-2 mt-2 mt-lg-0"
                size={mobileView ? "sm" : "lg"}
              >
                {tabRatio?.map((item) => (
                  <Button
                    onClick={() => setTabSelected(item)}
                    className={
                      tabSelected?.slug === item?.slug
                        ? "text-primary border-0 shadow-none"
                        : state?.darkMode
                        ? "text-white border-0 shadow-none"
                        : "text-secondary border-0 shadow-none"
                    }
                    style={{
                      backgroundColor:
                        tabSelected?.slug === item?.slug
                          ? state?.darkMode
                            ? "#2609b5"
                            : "#F3F4FF"
                          : state?.darkMode
                          ? "#606f7b"
                          : "#FFF",
                      fontSize: mobileView ? "12px" : "14px",
                      fontWeight: "500",
                    }}
                  >
                    {item?.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>

          <div className="d-flex flex-row flex-wrap mt-3 ">
            {parametersTemp
              ?.filter((item_) => item_?.section === tabSelected?.slug)
              ?.map((item, index) => (
                <Col key={index} sm="4" lg="3" md="4" className="mt-3">
                  <FormGroup check inline>
                    <Input
                      onChange={(e) => {
                        onChangeChecked(e, item);
                      }}
                      type="checkbox"
                      className="shadow-none"
                      checked={onCheckChecked(item)}
                    />
                    <Label check>{item?.valueDisplayName}</Label>
                  </FormGroup>
                </Col>
              ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ManageRatio;
