import React, { useState } from "react";
import {
  FaBell,
  FaBookOpen,
  FaCrown,
  FaPowerOff,
  FaSearch,
} from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { IoIosSwitch } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { Redbel } from "../../../assets/img";
import CInput from "../../../components/CInput";

const NavbarDesktop = ({
  token,
  user,
  darkMode,
  t,
  onChangeLanguage,
  search,
  setSearch,
  companies,
  getCompany,
  companyState,
  i18n,
  sectionActive,
  scrollToSection,
  subMenu,
  onLogout,
  settings,
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const pathName = location.pathname;
  const [errorLogo, setErrorLogo] = useState(false);

  const getColorText = () => {
    if (darkMode) {
      return "white";
    } else {
      return "#606F7B";
    }
  };

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div>
      <Navbar
        expand="md"
        color={"white"}
        className={`px-5  ${
          darkMode ? "bg-dark" : "bg-white"
        } d-none d-lg-block`}
      >
        <NavbarBrand tag={Link} to="/">
          <img
            src={errorLogo ? Redbel : settings?.logo}
            onError={(e) => setErrorLogo(true)}
            alt="logo"
            style={{ maxWidth: "120px", height: "40px" }}
          />
        </NavbarBrand>

        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            {token ? (
              <NavItem className="mx-3 d-flex align-items-center">
                <NavLink tag={Link} to="/">
                  <span
                    className={` ${
                      darkMode
                        ? "text-white"
                        : pathName === "/"
                        ? "fw-semibold text-primary"
                        : "text-dark"
                    } font-label`}
                  >
                    {t("navbar.FEED")}
                  </span>
                </NavLink>
              </NavItem>
            ) : null}
            <NavItem className="mx-3 d-flex align-items-center">
              <NavLink tag={Link} to="/market">
                <span
                  className={` ${
                    darkMode
                      ? "text-white"
                      : pathName.includes("/market")
                      ? " fw-semibold text-primary"
                      : "text-dark"
                  } font-label`}
                >
                  {t("navbar.MARKETS")}
                </span>
              </NavLink>
            </NavItem>
            <NavItem className="mx-3 d-flex align-items-center">
              <NavLink tag={Link} to="/screen">
                <span
                  className={` ${
                    darkMode
                      ? "text-white"
                      : pathName.includes("/screen")
                      ? " fw-semibold text-primary"
                      : "text-dark"
                  } font-label`}
                >
                  {t("navbar.SCREENS")}
                </span>
              </NavLink>
            </NavItem>
            <NavItem className="mx-3 d-flex align-items-center">
              <NavLink tag={Link} to="/news">
                <span
                  className={` ${
                    darkMode
                      ? "text-white"
                      : pathName.includes("/news")
                      ? " fw-semibold text-primary"
                      : "text-dark"
                  } font-label`}
                >
                  {t("navbar.NEWS")}
                </span>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                  <span
                    className={` ${
                      darkMode
                        ? "text-white"
                        : pathName.includes("/tools")
                        ? " fw-semibold text-primary"
                        : "text-dark"
                    } font-label`}
                  >
                    {t("navbar.TOOLS")}{" "}
                    <IoIosArrowDown color={getColorText()} size={16} />
                  </span>
                </DropdownToggle>
                <DropdownMenu left>
                  <DropdownItem className="dropdown-tools">
                    <div className="d-flex flex-row gap-2">
                      <div>
                        <div className="dropdown-item-tools">
                          <BsSearch size={25} color="#2609b5" />
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-center">
                        <p
                          className="h6 mb-0"
                          style={{ color: getColorText() }}
                        >
                          Search a stock screen
                        </p>
                        <span
                          style={{
                            fontSize: "13px",
                            color: getColorText(),
                          }}
                        >
                          Run queries on 10 years financial data
                        </span>
                      </div>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem> */}
          </Nav>
          <Nav className="ms-auto" navbar>
            {(pathName !== "/" && !token) || (pathName && token) ? (
              <NavItem>
                <NavLink>
                  <CInput
                    style={{ fontSize: "14px" }}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      getCompany({
                        variables: {
                          filter: e.target.value,
                        },
                      });
                    }}
                    borderColor={"border-primary"}
                    placeholder={t("home.search_company")}
                    widthInput={"250px"}
                    heightInput={"38px"}
                    leftIcon={<FaSearch width={20} height={20} />}
                    rightIcon={
                      search?.length > 0 && (
                        <IoClose color="#0e3dea" width={20} height={20} />
                      )
                    }
                    rightAction={() => setSearch("")}
                  />
                  {companies?.length > 0 && search?.length > 0 && (
                    <div
                      className={`position-absolute p-2 ${
                        darkMode ? "bg-secondary text-light" : "bg-light"
                      } mt-1 rounded shadow-sm z-1`}
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        width: "250px",
                      }}
                    >
                      {companies?.map((item) => (
                        <div
                          className="p-2 card-search"
                          style={{
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSearch("");
                            window.location.href = `/company/${item?.code}`;
                          }}
                        >
                          {item?.name}
                        </div>
                      ))}
                    </div>
                  )}
                </NavLink>
              </NavItem>
            ) : null}

            <NavItem className=" d-flex align-items-center">
              {/* User Info after login */}
              {token ? (
                <NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                      <div className={"border  rounded px-2 py-1"}>
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "500",
                            letterSpacing: "1.2px",
                            color: getColorText(),
                          }}
                        >
                          <FiUser size={14} /> {user?.firstName}
                          <MdOutlineKeyboardArrowDown />
                        </span>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem className="dropdown-user">
                        <div className="d-flex flex-row gap-2">
                          <div>
                            <IoIosSwitch size={14} color="#4651F3" />
                          </div>
                          <div>
                            <span
                              className={`${darkMode ? "text-light" : ""}`}
                              style={{
                                fontSize: 14,
                                fontWeight: "500",
                              }}
                            >
                              {t("navbar_user.profile")}
                            </span>
                          </div>
                        </div>
                      </DropdownItem>
                      <DropdownItem className="dropdown-user">
                        <div className="d-flex flex-row gap-2">
                          <div>
                            <FaBell size={14} color="#4651F3" />
                          </div>
                          <div>
                            <span
                              className={`${darkMode ? "text-light" : ""}`}
                              style={{ fontSize: 14, fontWeight: "500" }}
                            >
                              {t("navbar_user.alerts")}
                            </span>
                          </div>
                        </div>
                      </DropdownItem>
                      <DropdownItem className="dropdown-user">
                        <div className="d-flex flex-row gap-2">
                          <div>
                            <FaBookOpen size={14} color="#4651F3" />
                          </div>
                          <div>
                            <span
                              className={`${darkMode ? "text-light" : ""}`}
                              style={{ fontSize: 14, fontWeight: "500" }}
                            >
                              {t("navbar_user.notebook")}
                            </span>
                          </div>
                        </div>
                      </DropdownItem>
                      <DropdownItem className="dropdown-user">
                        <div className="d-flex flex-row gap-2">
                          <div>
                            <FaCrown size={14} color="#4651F3" />
                          </div>
                          <div>
                            <span
                              className={`${darkMode ? "text-light" : ""}`}
                              style={{ fontSize: 14, fontWeight: "500" }}
                            >
                              {t("navbar_user.upgrade")}
                            </span>
                          </div>
                        </div>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem className="dropdown-user">
                        <div
                          className="d-flex flex-row gap-2"
                          onClick={onLogout}
                        >
                          <div>
                            <FaPowerOff size={14} color="#4651F3" />
                          </div>
                          <div>
                            <span
                              className={`${darkMode ? "text-light" : ""}`}
                              style={{ fontSize: 14, fontWeight: "500" }}
                            >
                              {t("navbar_user.Logout")}
                            </span>
                          </div>
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavItem>
              ) : (
                <NavLink tag={Link} to="/login">
                  <div className={" rounded px-3  py-1 bg-primary"}>
                    <span className="text-light fw-semibold font-label">
                      <FiUser size={16} /> {t("navbar.LOGIN")}
                    </span>
                  </div>
                </NavLink>
              )}
            </NavItem>
            {!token && (
              <NavItem className=" d-flex align-items-center">
                <NavLink tag={Link} to="/register">
                  <div className={`border rounded border-primary px-2 py-1 `}>
                    <span className="text-primary fw-semibold font-label">
                      {t("navbar.GETFREEACCOUNT")}
                    </span>
                  </div>
                </NavLink>
              </NavItem>
            )}
            <NavItem className=" d-flex align-items-center">
              <NavLink>
                <div className="language-switch-container">
                  <span
                    className={`language-label ${
                      i18n?.language === "en" ? "active" : ""
                    }`}
                  >
                    EN
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={i18n?.language === "id"}
                      onChange={onChangeLanguage}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span
                    className={`language-label ${
                      i18n?.language === "id" ? "active" : ""
                    }`}
                  >
                    ID
                  </span>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      {pathName.includes("company") && (
        <Navbar
          expand="sm"
          className={`px-5 shadow-sm py-0 ${
            darkMode ? "bg-dark" : "bg-brown"
          } d-none d-lg-block`}
          style={{
            cursor: "pointer",
          }}
        >
          <Nav>
            {subMenu.map((item, index) => (
              <NavItem
                onClick={() => {
                  scrollToSection(item?.slug);
                }}
                key={index}
                className={
                  item?.slug === sectionActive &&
                  `border-bottom border-primary border-2`
                }
              >
                <NavLink
                  className={`text-decoration-none  font-label ${
                    item?.slug === sectionActive
                      ? "text-primary fw-bold"
                      : "text-secondary"
                  } `}
                >
                  {!item?.label ? companyState?.name : item?.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Navbar>
      )}
    </div>
  );
};

export default NavbarDesktop;
