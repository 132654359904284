import { gql } from "@apollo/client";

const QuerySettingApplication = gql`
  query Setting {
    settings {
      name
      descriptions
      aboutUs
      privacy
      privacyUpdated
      terms
      termsUpdated
      logo
    }
  }
`;

export default QuerySettingApplication;
