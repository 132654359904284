import { gql } from "@apollo/client";

const AuthManually = gql`
  mutation AuthManual($username: String!, $password: String!) {
    authToken(username: $username, password: $password) {
      token
      user {
        firstName
        lastName
        id
      }
    }
  }
`;

export default AuthManually;
