import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardImg, Col, Row } from "reactstrap";
import CBreadcrumb from "../../components/CBreadcrumb";

const DetailNews = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [news] = useState(state?.news);
  const [otherNews] = useState(state?.otherNews);
  const [currentPath] = useState("/news/" + news?.id);
  const { t } = useTranslation("global");

  const breadcrumbItems = [
    { label: t("news.news"), path: "/news" },
    { label: news?.title, path: `/news/${news?.id}` },
  ];

  const onDetailNews = (data) => {
    navigate(`/news/${data?.id}`, {
      state: {
        news: data,
      },
    });
    window.location.reload();
  };

  return (
    <div>
      <CBreadcrumb items={breadcrumbItems} currentPath={currentPath} />
      <Card>
        <CardBody>
          <div className="d-flex flex-column">
            <span className="font-extra-large fw-semibold">{news?.title}</span>
            <span className="font-label">
              {moment(news?.dateCreated).format("DD MMMM YYYY")}
            </span>
          </div>
          <div>
            <CardImg src={news?.bannerUrl} alt={news?.title} className="mt-3" />
          </div>
          <div className="my-3">
            <span
              className={`font-label`}
              dangerouslySetInnerHTML={{
                __html: news?.content,
              }}
            />
          </div>
        </CardBody>
      </Card>
      {otherNews?.length > 0 && (
        <div className="my-3">
          <span className="font-title fw-bold">{t("news.other_news")}</span>
        </div>
      )}

      <Row>
        {otherNews?.map((news, index) => (
          <Col md="3" lg="3" sm="3" className="mb-4" key={index}>
            <Card
              onClick={() => onDetailNews(news)}
              style={{ cursor: "pointer" }}
            >
              <CardImg
                src={news?.bannerUrl}
                height={"200px"}
                style={{
                  objectFit: "cover",
                }}
                alt={`News ${index + 1}`}
              />
              <CardBody>
                <div className=" d-flex flex-column">
                  <span className="font-description ">
                    {moment(news?.dateCreated).format("DD MMMM YYYY")}
                  </span>
                  <span className="font-label news-title">{news?.title}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DetailNews;
