import React, { useContext, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Redbel } from "../../assets/img";
import { GlobalContext } from "../../contexts/GlobalContext";

const AboutUs = () => {
  const { settings } = useContext(GlobalContext);
  const [errorLogo, setErrorLogo] = useState(false);
  return (
    <div>
      <Card>
        <CardBody>
          <div className="text-center ">
            <p className="font-extra-large fw-semibold  my-2">About Us</p>
            <img
              alt="logo"
              src={errorLogo ? Redbel : settings?.logo}
              height={"150px"}
              width={"300px"}
              onError={(e) => setErrorLogo(true)}
            />
          </div>

          <div dangerouslySetInnerHTML={{ __html: settings?.aboutUs }} />
        </CardBody>
      </Card>
    </div>
  );
};

export default AboutUs;
