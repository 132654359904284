import { gql } from "@apollo/client";

const GetCompany = gql`
  query CompanyLandingPage($filter: String, $id: String) {
    company(filter: $filter, id: $id) {
      id
      name
      code
    }
  }
`;

export default GetCompany;
