import React, { useContext, useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { GlobalContext } from "../contexts/GlobalContext";

const CInput = ({
  leftIcon,
  leftAction,
  rightIcon,
  rightAction,
  heightInput,
  widthInput,
  borderColor,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { state } = useContext(GlobalContext);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <InputGroup
      className={`d-flex custom-input ${isFocused ? "focused" : ""} ${
        state?.darkMode ? "" : "border"
      } ${borderColor}`}
      style={{
        height: heightInput ? heightInput : "50px",
        width: widthInput ? widthInput : "100%",
      }}
    >
      {/* Icon Kiri */}
      {leftIcon && (
        <InputGroupText
          onClick={rightAction || null}
          style={{ cursor: "pointer" }}
        >
          {leftIcon}
        </InputGroupText>
      )}

      <Input
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="shadow-none z-0"
      />

      {/* Icon Kanan */}
      {rightIcon && (
        <InputGroupText
          onClick={rightAction || null}
          style={{ cursor: "pointer" }}
        >
          {rightIcon}
        </InputGroupText>
      )}
    </InputGroup>
  );
};

export default CInput;
