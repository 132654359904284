import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Redbel } from "../assets/img";
import { GlobalContext } from "../contexts/GlobalContext";

const MetaHelmet = ({ title, description, image, url }) => {
  const { settings } = useContext(GlobalContext);
  return (
    <Helmet>
      <title>{title ? `${title}` : "Redbel"}</title>
      <meta
        name="description"
        content={description ? `${description}` : settings?.descriptions}
      />
      <meta property="og:title" content={title ? `${title}` : settings?.name} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={Redbel} />
      <meta property="og:url" content={url} />
    </Helmet>
  );
};

export default MetaHelmet;
