import "bootstrap/dist/css/bootstrap.min.css";
import { useContext } from "react";
import "./App.css";
import { GlobalContext } from "./contexts/GlobalContext";
import AppRouter from "./routes/AppRouter";

function App() {
  const { state } = useContext(GlobalContext);

  return (
    <div className={`App ${state?.darkMode ? "dark-mode" : "light-mode"}`}>
      <AppRouter />
    </div>
  );
}

export default App;
