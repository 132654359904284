import React, { useRef, useState } from "react";
import Chart from "react-apexcharts";
import { Chart as ChartJS2 } from "react-chartjs-2";
import { IoMdCheckbox } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

import { HiOutlinePresentationChartLine } from "react-icons/hi";
import { Button, ButtonGroup, Card, CardBody, Nav, NavItem } from "reactstrap";
import { NoData } from "../../../assets/img";
import CEmpty from "../../../components/CEmpty";
import IsMobile from "../../../utils/IsMobile";

const SectionChart = ({
  dataSet,
  dataOptions,
  filterCategory,
  filterCategorySelected,
  filterDate,
  filterDateSelected,
  onSelectCategoryChart,
  onSelectDateChart,
  isCompareActive,
  setIsOpenCompre,
  isTradingView,
  onSwitchChange,
  dataSetTradingView,
  optionsTradingView,
  t,
  darkMode,
}) => {
  const chartRef = useRef(null);

  const [mobileView] = useState(IsMobile());
  const [datasetsVisibility, setDatasetsVisibility] = useState({
    volume: true,
    price: true,
    ema50: false,
    ema100: false,
    ema200: false,
    sma50: false,
    sma100: false,
    sma200: false,
    stockPe: false,
    eps: false,
    dividendYield: false,
    marketCapital: false,
  });

  const handleCheckboxChange = (slug) => {
    setDatasetsVisibility((prev) => ({
      ...prev,
      [slug]: !prev[slug],
    }));
    const chart = chartRef.current;
    const datasetIndex = chart.data.datasets.findIndex(
      (dataset) => dataset.slug === slug
    );
    chart.isDatasetVisible(datasetIndex)
      ? chart.hide(datasetIndex)
      : chart.show(datasetIndex);
  };

  return (
    <Card className="mt-4 shadow-sm">
      <CardBody>
        <div className="d-flex flex-lg-row-reverse flex-column justify-content-between">
          <div>
            <div className="py-2 rounded">
              <span className="font-label fw-bold ">
                <HiOutlinePresentationChartLine size={20} />{" "}
                {t("detail.switch_to_tradingview")}
              </span>
              <label className="switch ms-2">
                <input
                  type="checkbox"
                  checked={isTradingView}
                  onChange={onSwitchChange}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div>
            {!isTradingView && (
              <div className="d-flex flex-row gap-2">
                <div className="horizontal-list-container">
                  <Nav className="horizontal-list gap-2" pills>
                    <NavItem
                      onClick={() => setIsOpenCompre(true)}
                      className={
                        isCompareActive ? "filter-chart-active" : "filter-chart"
                      }
                    >
                      <div>
                        {t("detail.compare") +
                          " " +
                          filterCategorySelected?.label}
                      </div>
                    </NavItem>
                    {filterCategory?.map((item) => (
                      <NavItem
                        onClick={() => onSelectCategoryChart(item)}
                        className={
                          filterCategorySelected?.slug === item?.slug &&
                          !isCompareActive
                            ? "filter-chart-active"
                            : "filter-chart"
                        }
                      >
                        <div>{item?.label}</div>
                      </NavItem>
                    ))}
                  </Nav>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          {/* Tab Filter Date*/}
          <ButtonGroup className="my-2 border" size={mobileView ? "sm" : "lg"}>
            {filterDate?.map((item) => (
              <Button
                onClick={() => onSelectDateChart(item)}
                className={
                  filterDateSelected?.label === item?.label
                    ? "text-primary border-0 shadow-none"
                    : darkMode
                    ? "text-white border-0 shadow-none"
                    : "text-secondary border-0 shadow-none"
                }
                style={{
                  backgroundColor:
                    filterDateSelected?.label === item?.label
                      ? darkMode
                        ? "#2609b5"
                        : "#F3F4FF"
                      : darkMode
                      ? "#606f7b"
                      : "#FFF",
                  fontSize: mobileView ? "12px" : "14px",
                  fontWeight: "500",
                }}
              >
                {item?.label}
              </Button>
            ))}
          </ButtonGroup>
        </div>
        {!isTradingView ? (
          <div>
            {/* Chart */}
            <div
              style={{
                marginTop: "20px",
                width: "100%",
                height: mobileView ? "300px" : "400px",
              }}
            >
              {dataSet?.labels?.length > 0 ? (
                <ChartJS2 ref={chartRef} data={dataSet} options={dataOptions} />
              ) : (
                <CEmpty
                  className="d-flex justify-content-center align-items-center flex-column"
                  image={<img src={NoData} alt="No Data" width={250} />}
                  title={t("detail.no_data")}
                  description={t("detail.no_data_filter")}
                />
              )}
            </div>

            <div className="py-3 d-flex flex-row flex-wrap gap-2 justify-content-center">
              {filterCategorySelected?.slug === "closingPrice" &&
                !isCompareActive &&
                dataSet?.datasets?.map((item) => (
                  <>
                    <div
                      className="gap-1 d-flex flex-row align-items-center"
                      onClick={() => handleCheckboxChange(item?.slug)}
                    >
                      {datasetsVisibility[item?.slug] ? (
                        <IoMdCheckbox color={item?.borderColor} size={20} />
                      ) : (
                        <MdCheckBoxOutlineBlank size={20} />
                      )}

                      <span
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {item?.label}
                      </span>
                    </div>
                  </>
                ))}
            </div>
          </div>
        ) : (
          <div>
            {dataSetTradingView?.length > 0 ? (
              <Chart
                options={optionsTradingView}
                series={dataSetTradingView}
                type="candlestick"
                height={400}
              />
            ) : (
              <CEmpty
                className="d-flex justify-content-center"
                title="No Data"
              />
            )}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default SectionChart;
