import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";
import CBreadcrumb from "../../components/CBreadcrumb";
import CLoading from "../../components/CLoading";
import { GlobalContext } from "../../contexts/GlobalContext";
import QueryCompareSector from "../../graphQL/Query/QueryCompareSector";
import { isCurrencyWithOutRp } from "../../utils/isCurrency";
import fieldPeerComparison from "./../company/fieldTable/fieldPeerComparison.json";

const CompareSector = () => {
  const { name } = useParams();
  const [sector, setSector] = useState([]);
  const [field] = useState(fieldPeerComparison);
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [currentPath] = useState(`/sector/compare/${name}`);

  const breadcrumbItems = [
    { label: "Sector", path: "/screen" },
    { label: name, path: `/sector/compare/${name}` },
  ];

  const [fetchSector, { loading }] = useLazyQuery(QueryCompareSector, {
    onCompleted: (data) => {
      setSector(data?.company);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    fetchSector({ variables: { sector: name } });
    // eslint-disable-next-line
  }, []);
  console.log(sector);
  return (
    <div>
      {loading && <CLoading />}
      <CBreadcrumb items={breadcrumbItems} currentPath={currentPath} />
      <Card>
        <CardBody>
          <div>
            <p className="font-large fw-semibold">
              SECTORS {name.toLocaleUpperCase()}
            </p>
          </div>
          <div className="table-container">
            <Table
              className={`detail-table ${state?.darkMode && "table-dark"}`}
            >
              <thead>
                <tr className="peer-tr">
                  {field?.map((item, index) => (
                    <th
                      className={
                        index === 0
                          ? "peer-first-col font-label"
                          : index === 1
                          ? "peer-second-col font-label"
                          : "font-label "
                      }
                      id={item?.slug}
                      key={index}
                    >
                      {item?.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sector?.map((item, index) => (
                  <tr key={index} className="peer-tr">
                    <td className="peer-first-col font-label">{index + 1}</td>
                    <td
                      className="peer-second-col font-label text-primary"
                      onClick={() => navigate(`/company/${item?.code}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {item?.name}
                    </td>
                    <td className="font-label">
                      {isCurrencyWithOutRp(
                        item?.stockSummaries?.length > 0
                          ? item?.stockSummaries[0]?.price
                          : 0
                      )}
                    </td>
                    <td className="font-label">
                      {isCurrencyWithOutRp(
                        item?.ratio?.length > 0
                          ? item?.ratio[0]?.priceToEarning
                          : 0
                      )}
                    </td>
                    <td className="font-label">
                      {isCurrencyWithOutRp(
                        item?.ratio?.length > 0
                          ? item?.ratio[0]?.marketCapital
                          : 0
                      )}
                    </td>
                    <td className="font-label">
                      {item?.ratio?.length > 0
                        ? item?.ratio[0]?.dividendYield
                        : 0}
                      %
                    </td>
                    <td className="font-label">
                      {isCurrencyWithOutRp(
                        item?.quarterlyIncome?.length > 0
                          ? item?.quarterlyIncome[0]?.netProfitLatestQuarter
                          : 0
                      )}
                    </td>
                    <td className="font-label">
                      {item?.quarterlyIncome?.length > 0
                        ? item?.quarterlyIncome[0]?.yoyQuarterlyProfitGrowth
                        : 0}
                      %
                    </td>
                    <td className="font-label">
                      {isCurrencyWithOutRp(
                        item?.quarterlyIncome?.length > 0
                          ? item?.quarterlyIncome[0]?.salesLatestQuarter
                          : 0
                      )}
                    </td>
                    <td className="font-label">
                      {item?.quarterlyIncome?.length > 0
                        ? item?.quarterlyIncome[0]?.yoyQuarterlySalesGrowth
                        : 0}
                      %
                    </td>
                    <td className="font-label">
                      {item?.annualIncome?.length > 0
                        ? item?.annualIncome[0]?.returnOnCapitalEmployed
                        : 0}
                      %
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CompareSector;
