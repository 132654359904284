import { gql } from "@apollo/client";

const ResetPasswordNew = gql`
  mutation MyMutation($code: String!, $email: String!, $password: String!) {
    resetPassword(code: $code, password: $password, email: $email) {
      message
      status
      error
    }
  }
`;

export default ResetPasswordNew;
