import { gql } from "@apollo/client";

const AuthGoogle = gql`
  mutation MyMutation($token: String!) {
    googleAuth(token: $token) {
      token
      user {
        firstName
        lastName
        id
      }
    }
  }
`;

export default AuthGoogle;
